.lsm {
    &__placeholder {
        text-align: left;
        background: #fff;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 10px;
        & > div {
            h3 {
                font-size: 24px;
                font-weight: 600;
            }
            p {
                font-size: 14px;
            }
        }
    }
    &__upload {
        margin-left: 10px;
        .btn-upload {
            width: 208px;
            height: 50px;
            display: flex;
            align-items: center;
            padding-left: 14px;
            box-sizing: border-box;
            border: 1px solid #cacaca;
            background: #fff;
            border-radius: 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            color: #717579;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            svg {
                margin-right: 11px;
                path {
                    transition: all 0.2s ease-in-out;
                }
            }
            &:hover {
                color: #fff;
                background: #f40027;
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}
