.pods {
    &__bar {
        display: flex;
        align-items: center;
        @media (max-width: 450px) {
            zoom: 0.75;
        }
        &__settings {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            margin-left: 10px;
            button {
                margin-right: 10px;
                transition: all 0.2s ease-in-out;
                svg {
                    path {
                        transition: all 0.2s ease-in-out;
                    }
                }
                &:hover {
                    color: #fff;
                    background: #fa3535;
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .custom-sort-icons {
        svg {
            opacity: 1 !important;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 13px;
        line-height: 22px;
        color: #000000;
        text-decoration: none;
    }
    &__title--black-purple {
        display: flex;
        flex-direction: column;
        span {
            &:first-child {
                font-weight: 600;
                font-size: 13px;
                line-height: 22px;
                color: #000000;
            }
            &:last-child {
                font-weight: 400;
                font-size: 13px;
                line-height: 22px;
                color: #5a45aa;
            }
        }
    }
    &__title--black-grey {
        display: flex;
        flex-direction: column;
        span {
            &:first-child {
                font-weight: 600;
                font-size: 13px;
                line-height: 22px;
                color: #000000;
            }
            &:last-child {
                font-weight: 400;
                font-size: 13px;
                line-height: 22px;
                color: #717579;
            }
        }
    }
    &__status {
        display: flex;
        align-items: center;
        .info-popup {
            right: 30%;
            top: -95%;
        }
        & > button:first-child {
            background: #09bd3c;
            border-radius: 6px;
            min-width: -webkit-fill-available;
            width: 100%;
            max-width: 162px;
            height: 40px;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #ffffff;
            border: 0;
            cursor: pointer;
        }
        & > button:nth-child(2) {
            display: flex;
            background: transparent;
            border: 0;
            margin-left: 5px;
            cursor: pointer;
            svg {
                pointer-events: none;
            }
        }
    }
    .rdt_TableHeadRow,
    .rdt_TableRow {
        padding-left: 5px;
    }
    .rdt_TableHead {
        min-height: 65px;
    }
    .rdt_TableRow {
        display: flex;
        align-items: flex-start;
        padding-top: 21px;
        padding-bottom: 21px;
        min-height: auto;
        .rdt_TableCell {
            &:last-child {
                & > div {
                    width: 80%;
                    max-width: 250px;
                }
            }
        }
    }
}

.btn__upload-files,
.btn__test-market {
    width: 208px;
    height: 60px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 10px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #717579;
    align-items: center;
    display: flex;
    padding-left: 14px;
    box-sizing: border-box;
    cursor: pointer;
    svg {
        margin-right: 11px;
    }
    @media (max-width: 1200px) {
        width: 160px;
    }
    @media (max-width: 700px) {
        width: 60px;
        font-size: 0;
    }
}

.btn__settings {
    height: 60px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 10px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #717579;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    width: 60px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.popup-overlay {
    background: #000;
    opacity: 0.3;
}

#popup-root {
    .popup-content {
        background: #fff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 12px;
    }
}

.new-compaign-popup-content {
    width: 95.6%;
    height: 95%;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 2.5% !important;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-compaign-popup-content {
    overflow-x: hidden;
}

.new-compaign-popup__popup {
    width: 97.5%;
    padding: 25px;
    display: flex;
    .cancel {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 100;
        display: block;
    }
    .first_popup_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 80%;
        width: 100%;
    }
    .first_popup_content .fifth-step,
    .first_popup_content .first-step,
    .first_popup_content .fourth-step,
    .first_popup_content .second-step,
    .first_popup_content .third-step {
        margin: 0 auto;
        width: 100%;
        max-width: 480px !important;
    }
}

.hiddenNotifyBtnCheckPassword,
.hiddenNotifyBtnEmail,
.hiddenNotifyBtnPassword,
.hiddenNotifyBtnPhone,
.hiddenNotifyBtnUsername {
    display: none;
}

.hiddenNotifyBtnPasswordSecond {
    display: none;
}

.first_popup_content .button-group {
    display: flex;
    justify-content: flex-start;
    margin: 21px auto 0;
    max-width: 387px;
    width: 100%;
}

.new-compaign-popup-content {
    width: 95.6%;
    height: 95%;
    left: 50% !important;
    transform: translateX(-50%);
    top: 2.5% !important;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 12px;
}

.new-compaign-popup__popup {
    width: 97.5%;
    padding: 25px;
    display: flex;
}

.new-compaign-popup-overlay {
    background: #000;
    opacity: 0.3;
}

.create-campaigns {
    width: 100%;
}

.CampaignType,
.PodSelected,
.PromotionalName,
.SelectedMediaType,
.TestMarket {
    display: none;
}

.SelectedLocation {
    display: none;
}

.first_popup_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 80%;
    width: 100%;
}

.first_popup_content .fifth-step,
.first_popup_content .first-step,
.first_popup_content .fourth-step,
.first_popup_content .second-step,
.first_popup_content .third-step {
    max-width: 387px;
    margin: 0 auto;
    width: 100%;
}

.first_popup_content .button-group {
    display: flex;
    justify-content: center;
    margin: 21px auto 0;
    max-width: 387px;
    width: 100%;
}

.first_popup_content h1 {
    margin: 0 0 9px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: #717579;
}

.first_popup_content h4,
.first_popup_content p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #171b1e;
}

.first_popup_content h4 {
    margin: 0 0 4px;
    font-size: 26px;
    line-height: 28px;
}

.first_popup_content p {
    margin: 0 0 16px;
    font-size: 18px;
    line-height: 25px;
}

.first_popup_content h4,
.first_popup_content p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #171b1e;
}

.first_popup_content h6,
.first_popup_content span {
    font-family: Nunito Sans;
    font-style: normal;
    text-align: center;
}

.first_popup_content h6 {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #171b1e;
}

.first_popup_content span {
    display: block;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #717579;
    margin: 0 0 18px;
}

.first_popup_content input {
    background: #fff;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    padding: 16px;
    outline: none;
}

.hiddenNotifyBtnCheckPassword,
.hiddenNotifyBtnEmail,
.hiddenNotifyBtnPassword,
.hiddenNotifyBtnPhone,
.hiddenNotifyBtnUsername {
    display: none;
}

.hiddenNotifyBtnPasswordLength {
    display: none;
    border: 0 !important;
}

.first_popup_content .cancel-btn {
    background: #fff;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #2f80ed;
    width: 188px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.first_popup_content .next-btn {
    background: #09bd3c;
    border: 1px solid #09bd3c;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #fff;
    width: 188px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    display: block;
}

.new-compaign-popup-content svg {
    display: block !important;
    margin-left: auto;
    cursor: pointer;
}

.first_popup_content .second-step {
    max-width: 496px !important;
}

.first_popup_content input.podsNewCompany {
    display: none;
}

.first_popup_content .third-step .dropdown {
    max-width: 387px;
}

.first_popup_content .dropdown .dropdown {
    height: 60px;
}

.first_popup_content .dropdown .dropdown {
    width: 100% !important;
}

.first_popup_content .dropdown {
    text-align: left;
    position: relative;
    margin: 0 auto;
    width: 100%;
}

.first_popup_content .dropdown > div:nth-child(2):not(.dropdownSecond) {
    border: 0 !important;
}

.first_popup_content .popup__widgets {
    width: 100%;
    margin: 0 -5px;
}

.new-compaign-popup__popup .popup__widgets {
    height: 507px;
}

.popup__widgets {
    display: flex;
    justify-content: center;
}

.first_popup_content .fifth-step,
.first_popup_content .fourth-step {
    max-width: 795px;
}

.first_popup_content input {
    background: #fff;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    padding: 16px;
    outline: none;
}

.first_popup_content .popup__widgets {
    width: 100%;
    margin: 0 -5px;
}

.first_popup_content .widgets-col {
    width: 50%;
    padding: 0 5px;
    display: flex;
}

.popup__widgets .widget__single {
    background: #fff;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 10px;
}

.first_popup_content .widget__single {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.popup__widgets .widget__single .widget__pod__info {
    padding: 0 11px 0 7px;
}

.sidebar .menu__item a {
    font-weight: 600;
}

.first_popup_content .third-step .dropdown {
    border-radius: 10px;

    &:last-child {
        margin-top: 9px;
    }
}

.location-list-items,
.location-list-items-remove,
.testMarketsLists,
.mediaTypeItemList,
.mediaTypeItemListRemove {
    max-height: 428px;
    overflow-y: auto;
    margin: 0;
    list-style-type: none;
    padding: 0 0 0 18px;

    &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display: none;
    }

    li {
        text-align: left;

        a {
            cursor: pointer;
            display: block !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 31px !important;
            color: #171b1e !important;
            width: 260px;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.create-campaigns {
    .popup__widgets .widget__single .widget__pod__info {
        padding: 11px 11px 9px 18px;
        border-bottom: 1px solid #eeeeee;

        .widget__pod__info__successes--search {
            h5 {
                display: flex;
                align-items: flex-start;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: #171b1e;
            }
        }

        .widget__pod__info__successes--btn {
            background: #f2994a;
            border-radius: 4px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #ffffff;
            width: 108px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            transition: all 0.2s ease-in-out;

            &:hover {
                filter: contrast(1.2);
            }

            &--blue {
                background: #56ccf2;
            }
        }

        input {
            width: 229px;
            height: 34px;
            box-sizing: border-box;
            padding-left: 12px;
            padding-right: 12px;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            border-radius: 4px;
            border: 1px solid #eeeeee;
            color: #d7d7d7;

            &::placeholder {
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                color: #d7d7d7;
            }
        }
    }

    .widget__pod__info__successes {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.fourth-step .popup__success .widgets-col:nth-child(2) .widget__pod__info {
    min-height: auto !important;
}

.fourth-step {
    .dropdown {
        height: 60px;
    }
}

.fifth-step {
    max-width: 1289px !important;

    .widget__pod__info__successes--search {
        span {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            /* identical to box height */

            /* Primary Text */

            color: #171b1e;
            margin-bottom: 0;
        }
    }
}

.create-campaigns .popup__widgets .widget__single .widget__pod__info {
    display: flex;
    align-items: center;
    height: 60px;
    box-sizing: border-box;
}

.popup__success__six-steps {
    & > div {
        & > div {
            display: flex;
        }
    }
}

.last_popup_content {
    height: auto !important;
    padding-bottom: 69px;

    .button-group-last {
        justify-content: left;

        .cancel-btn {
            border: 1px solid #eeeeee;
            border-radius: 4px;
            width: 108px;
            height: 34px;
        }

        .next-btn {
            border: 1px solid #56ccf2;
            background: #56ccf2;
            border-radius: 4px;
            width: 108px;
            height: 34px;
        }
    }

    h5 {
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: #171b1e;
        text-align: left;
    }

    span {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #717579;
        text-align: left !important;
    }

    hr {
        border: 0;
        height: 1px;
        background: #e8e8e8;
    }

    & > div {
        h5 {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #171b1e;
        }

        & > div {
            span {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: #717579;
                margin-bottom: 0;
                width: 250px;

                &:last-child {
                    color: #171b1e;
                }
            }
        }
    }
}

.dropdowns__row {
    .dropdown {
        & > div:nth-child(3) {
            & > div {
                & > div {
                    overflow: hidden;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}

.uploadSwitchSelector {
}

.upload-new-directory_inner-create-file {
    min-height: 265px;
}

.zones-table > div > div {
    min-width: 1450px;
}

.btn__compaing__upload__files {
    margin-right: 10px;

    & > div {
        display: flex;
        align-items: center;
    }
}

.upload-files-popup-content {
    width: 95.6%;
    height: 95%;
    left: 50% !important;
    transform: translateX(-50%);
    top: 2.5% !important;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-files-popup__container {
    .close {
        position: absolute;
        right: 26px;
        top: 27px;
        background: transparent;
        padding: 0;
        border: 0;
        cursor: pointer;
        outline: none;

        svg {
            display: block;
        }
    }

    h1 {
        margin: 0 0 9px;
        font-weight: 800;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        color: #717579;
    }

    h4 {
        font-weight: 400;
        text-align: center;
        color: #171b1e;
        margin: 0 0 4px;
        font-size: 26px;
        line-height: 28px;
    }

    p {
        margin: 0 0 16px;
        font-size: 18px;
        line-height: 25px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        color: #171b1e;
    }

    h6 {
        margin: 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #171b1e;
        text-align: center;
    }

    span {
        display: block;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #717579;
        margin: 0 0 18px;
        text-align: center;
    }

    .button-group {
        display: flex;
        justify-content: flex-start;
        margin: 21px auto 0;
        max-width: 387px;
        width: 100%;

        .cancel-btn {
            background: #fff;
            border: 1px solid #d7d7d7;
            box-sizing: border-box;
            border-radius: 10px;
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #2f80ed;
            width: 188px;
            height: 49px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                border: 1px solid #2f80ed;
                color: #fff;
                background-color: #2f80ed;
            }
        }

        .next-btn {
            background: #09bd3c;
            border: 1px solid #09bd3c;
            box-sizing: border-box;
            border-radius: 10px;
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #fff;
            width: 188px;
            height: 49px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                border: 1px solid #09bd3c;
                color: #09bd3c;
                background-color: #fff;
            }
        }
    }

    .dropdown {
        &:first-of-type {
            margin-bottom: 10px;
        }

        svg {
            display: flex;
        }
    }

    .list-filtration {
        width: 489px;
        height: 507px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 10px;

        &__row {
            //width: 838px;
            display: flex;

            .list-filtration {
                width: 500px;

                &--upload {
                    margin-left: 11px;
                    width: 500px;
                }
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 21px 10px 18px;
            border-bottom: 1px solid #eeeeee;

            h5 {
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: #171b1e;
            }

            button {
                height: 34px;
                width: 136px;
                background: #56ccf2;
                border-radius: 4px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #ffffff;
                border: 0;
                cursor: pointer;
                outline: none;
                transition: all 0.2s ease-in-out;

                &:hover {
                    filter: contrast(1.2);
                }
            }

            input {
                width: 229px;
                height: 34px;
                background: #ffffff;
                border: 1px solid #eeeeee;
                border-radius: 4px;
                padding: 0 12px;
                box-sizing: border-box;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                color: #171b1e;
                outline: none;

                &::placeholder {
                    color: #d7d7d7;
                }
            }
        }

        &__content {
            &--stores {
                ul {
                    li {
                        background: url("./add-plus.svg") no-repeat 96% !important;

                        &.active {
                            color: #fff !important;
                            background-color: #f2994a !important;
                            background-image: url("./remove-icon.svg") !important;
                        }

                        &:hover {
                            color: #fff !important;
                            background-color: #f2994a !important;
                            background-image: url("./add-plus-hover.svg") !important;
                        }
                    }
                }
            }

            &--dir {
                & > ul {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                    overflow-y: auto;
                    height: 450px;

                    &::-webkit-scrollbar {
                        width: 16px;
                        border-radius: 20px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #babac0;
                        border-radius: 16px;
                        border: 4px solid #fff;
                    }

                    &::-webkit-scrollbar-button {
                        display: none;
                    }

                    & > li {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        &:last-child {
                            span {
                                border-bottom: none;
                            }
                        }

                        & > span {
                            margin-bottom: 0;
                            height: 40px;
                            padding-left: 18px;
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid #eeeeee;
                            cursor: pointer;
                            transition: all 0.2s ease-in-out;
                            width: 100%;
                            box-sizing: border-box;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 31px;
                            color: #171b1e;

                            &.active {
                                &::after {
                                    transform: rotate(180deg);
                                }
                            }

                            &::after {
                                margin-left: auto;
                                margin-right: 20px;
                                transition: all 0.3s ease-in-out;
                            }
                        }

                        & > ul {
                            padding: 0;
                            margin: 0;
                            list-style-type: none;
                            display: none;
                            width: 100%;
                            border-bottom: 1px solid #eeeeee;

                            &.active {
                                display: block;
                            }

                            & > li {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                padding-left: 18px;
                                padding-right: 18px;
                                height: 40px;
                                box-sizing: border-box;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 31px;
                                margin-bottom: 2px;

                                button {
                                    display: flex;
                                    align-items: end;
                                    background: #c4c4c4;
                                    color: #fff;
                                    padding: 5px 10px;
                                    border-radius: 4px;
                                    transition: all 0.2s ease-in-out;
                                    border: 0;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: #f2994a;
                                    }

                                    svg {
                                        display: block;
                                        margin-right: 5px;
                                        transition: all 0.2s ease-in-out;

                                        path {
                                            fill: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            ul {
                padding: 0;
                list-style-type: none;
                margin: 0;
                overflow-y: auto;
                height: 450px;

                &::-webkit-scrollbar {
                    width: 16px;
                    border-radius: 20px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #babac0;
                    border-radius: 16px;
                    border: 4px solid #fff;
                }

                &::-webkit-scrollbar-button {
                    display: none;
                }

                li {
                    height: 40px;
                    padding-left: 18px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #eeeeee;
                    background: url("./arrow-right.svg") no-repeat 96%;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        color: #fff;
                        background-color: #f2994a;
                        background-image: url("./arrow-right-hover.svg");
                    }
                }
            }
        }
    }
}

.popup-box {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.15) !important;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
}

.box {
    position: relative;
    width: 554px;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 70vh - 20px);
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    overflow: hidden;

    .file-upload {
        display: flex;
        align-items: center;
        max-width: 387px;
        height: 60px;
        border: 1px solid #eee;
        border-radius: 10px;

        label {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #171b1e;
            width: 100%;
            margin-left: 16px;
            cursor: pointer;
        }

        input {
            border: 0 !important;
            opacity: 0;
            position: absolute;
            z-index: -1;
            background: #fff;
            border: 1px solid #eee;
            box-sizing: border-box;
            border-radius: 10px;
            width: 100%;
            height: 60px;
            padding: 16px;
            outline: none;
        }

        button {
            background: #a6a6a6 !important;
            cursor: not-allowed !important;
            min-width: 119px;
            height: 49px;
            border: 0;
            border-radius: 8px !important;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #fff;
            margin-left: auto;
            margin-right: 6px;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #171b1e;
        text-align: left;
    }

    p {
        font-size: 15px;
        line-height: 20px;
        color: #717579;
        text-align: left;
        margin-bottom: 10px;
    }

    input {
        background: #fff !important;
        border: 1px solid #eee !important;
        box-sizing: border-box !important;
        border-radius: 10px !important;
        width: 100% !important;
        height: 60px !important;
        padding: 16px !important;
        outline: none !important;
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 25px !important;
        margin-bottom: 10px !important;
    }

    .MuiSwitch-root {
        margin-bottom: 0;

        .MuiSwitch-colorSecondary {
            &.Mui-checked + .MuiSwitch-track {
                background-color: #1976d2 !important;
            }

            &.Mui-checked {
                .MuiSwitch-thumb {
                    color: #1976d2 !important;
                }
            }

            .MuiSwitch-thumb {
                color: #efefef !important;
            }
        }

        .MuiSwitch-track {
            background: #5c5b5b !important;
        }
    }

    .MuiGrid-item {
        font-size: 15px;
        line-height: 20px;
        color: #717579;
    }

    .upload-new-directory_btn_group {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;

        .create-section-close {
            background: transparent;
            border: 1px solid #eee;
            box-sizing: border-box;
            border-radius: 4px;
            width: 108px;
            height: 34px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #2f80ed;
            margin-right: 7px;
            transition: all 0.3s ease-in-out;
        }

        button:last-of-type {
            background: #56ccf2;
            border: 1px solid #56ccf2;
            box-sizing: border-box;
            border-radius: 4px;
            width: 108px;
            height: 34px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #fff;
            margin-right: 7px;
        }
    }
}

.upload-files-popup-content {
    overflow: hidden;
}

.popup-box--upload {
    .file-upload {
        margin-bottom: 10px;

        button {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
        }
    }

    .create-section-close {
        display: flex;
        justify-content: center;

        &:hover {
            background-color: #56ccf2 !important;
            filter: contrast(1.2);
        }
    }
}

.switch-carousel-style.active {
    display: flex !important;
}

.upload-new-directory_inner-create-file-carousel {
    svg {
        display: flex !important;
    }
}

.popup-box--upload {
    display: none;

    &.active {
        display: block;
    }
}

.new-compaign-row {
    .btn__compaing__upload__files {
        margin-right: 0 !important;

        &:hover {
            svg {
                path {
                    fill: #fff !important;
                    stroke: transparent !important;
                }
            }
        }

        svg {
            path {
                fill: #707579 !important;
            }
        }
    }
}

.btn__compaing {
    width: 208px;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    box-sizing: border-box;
    border: 1px solid #cacaca;
    background: #fff;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #717579;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    svg {
        margin-right: 11px;
    }
}

.new-compaign-row .btn__compaing__upload__files svg path {
    fill: #ffffff !important;
}

.dashboard__bo {
}

#choosePodsMultiInputs {
    display: none;
}

#chooseYearsMultiInputs {
    display: none;
}

.choosePodsMulti {
    & > div {
        & > div:nth-child(1) {
            height: 60px;
            flex-wrap: nowrap;

            & > div {
                min-width: auto;
                //background: #F2994A;
                //color: #fff;
            }
        }

        & > div:nth-child(2) {
            height: 60px;

            & > div:first-child {
                display: none;
            }

            & > span {
                display: none;
            }
        }
    }

    & > div:nth-of-type(2) {
        box-shadow: none !important;
        margin-top: 0 !important;
        border: 1px solid #eeeeee !important;
        border-top: 0 !important;

        & > div {
            height: 100% !important;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }

            & > div {
                text-align: left;
                border-bottom: 1px solid #eeeeee;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;

                /* Gray 2 */

                color: #4f4f4f;
                height: 45px;
            }
        }
    }
}

.css-1rhbuit-multiValue {
    color: #ffffff;
    border-radius: 6px !important;
    background-color: #f2994a !important;

    & > div {
        color: #fff;
    }
}

.css-xb97g8 {
    border-radius: 6px !important;
}

.css-xb97g8:hover {
    background-color: #f2994a !important;

    svg {
        path {
            fill: #fff;
            border-radius: 6px !important;
        }
    }
}

.uploadFiles-content-slider {
    max-width: 489px;
    min-height: 507px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    position: relative;

    .uploadFiles-content-slider-item-pods {
        position: absolute;
        min-width: 100%;

        &.active-zones {
            left: -838px;
        }
    }

    .uploadFiles-content-slider-item-zones {
        position: absolute;
        left: 838px;
        min-width: 100%;

        &.disable-pods {
            left: 0;
        }

        &.active-stores {
            left: -838px;
        }
    }

    .uploadFiles-content-slider-item-stores {
        position: absolute;
        left: 838px;
        min-width: 100%;

        &.disable-zones {
            left: 0;
        }

        .uploadFiles-content-slider-item-content {
            li {
                background-image: none;

                &:hover {
                    background-image: none;
                }
            }
        }
    }

    .uploadFiles-content-slider-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        padding: 10px 18px 10px 18px;

        span {
            margin-bottom: 0;
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #171b1e;
        }

        input {
            width: 229px;
            height: 34px;
            border-radius: 4px;

            &::placeholder {
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                color: #d7d7d7;
            }
        }
    }

    .uploadFiles-content-slider-item-content {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            overflow-y: auto;
            height: 450px;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }

            li {
                text-align: left;
                height: 40px;
                display: flex;
                align-items: center;
                padding-left: 18px;
                border-bottom: 1px solid #eeeeee;
                background-image: url("./np_arrow_2335663.svg");
                background-repeat: no-repeat;
                background-position: 97% center;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:last-child {
                    border-bottom: 0;
                }

                &:hover {
                    background-color: #f2994a;
                    color: #fff;
                    background-image: url("./np_arrow_2335663_fff.svg");
                }
            }
        }
    }
}

.uploadFiles-content-slider-item-stores-upload {
    display: none;

    &.active {
        display: flex;
        flex-direction: column;
        max-width: 489px;
        margin-left: 11px;
        width: 100%;
        border: 1px solid #eeeeee;
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        .uploadFiles-content-slider-item-stores-upload-header {
            display: flex;
            justify-content: space-between;
            padding: 10px 18px 10px 18px;
            border-bottom: 1px solid #eeeeee;

            span {
                margin-bottom: 0;
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: #171b1e;
            }

            button {
                width: 136px;
                height: 34px;
                border-radius: 4px;
                border: 0;
                background: #56ccf2;
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #ffffff;
            }
        }

        .uploadFiles-content-slider-item-stores-upload-content {
            height: 100%;

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                text-align: left;
                overflow-y: auto;
                height: 100%;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background-color: rgba(0, 0, 0, 0.5);
                    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                }

                li {
                    min-height: 40px;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;

                    & > span {
                        padding-left: 18px;
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 0;
                        background-image: url(./icon_bottom_arrow.svg);
                        background-repeat: no-repeat;
                        background-position: 97% center;
                        border-bottom: 1px solid #eeeeee;
                        font-family: Nunito Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 31px;
                        /* or 221% */

                        color: #171b1e;
                    }

                    ul {
                        padding: 0;
                        margin: 0;
                        list-style-type: none;
                        overflow-y: hidden;
                        transition: all 0.3s ease-in-out;
                        display: none;

                        &.active {
                            display: flex;
                            flex-direction: column;
                        }

                        li {
                            background-image: none;
                            border-bottom: 0;
                            display: flex;
                            padding-right: 18px !important;
                            justify-content: space-between;

                            .upload-navigation-bar {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                a {
                                    margin-right: 10px;
                                    zoom: 0.75;

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }

                            & > span {
                                font-family: Nunito Sans;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 31px;
                                color: #171b1e;
                                background-image: none;
                                border-bottom: 0;
                            }

                            .default-switch {
                                margin-left: 50%;

                                .MuiSwitch-root {
                                    margin-bottom: 0;

                                    & > span {
                                        margin-bottom: 0;

                                        & > span {
                                            margin-bottom: 0;

                                            & > span {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.location-list-items {
    li {
        background: url("location-items.svg") no-repeat 95% center;
    }

    &-remove {
        li {
            background: url("location-items-remove.svg") no-repeat 95% center;
        }
    }
}

.mediaTypeItemList {
    li {
        background: url("location-items.svg") no-repeat 95% center;
    }
}

.mediaTypeItemListRemove {
    li {
        background: url("location-items-remove.svg") no-repeat 95% center;
    }
}

.fourth-step .popup__success .widgets-col:nth-child(2) .widget__pod__info {
    min-height: 44px;
}

.testMarketsLists {
    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: url("location-items-remove.svg") no-repeat 95% center;

        span {
            margin: 0;
            margin-left: auto;
            padding-right: 40px;
        }
    }
}

.popup__success__five-steps {
    .popup__widgets {
        .widgets-col:nth-child(3) {
            .widget__pod__info {
                padding-bottom: 15px;
                margin-bottom: 0;
            }

            ul {
                border-top: 0;
                padding-top: 10px;
                margin-top: 0;
            }
        }
    }
}

.testMarketsLists {
    li {
        cursor: pointer;
    }
}

.podsDropdown {
    &.input-valid {
        &::placeholder {
            color: red;
        }

        color: red;
        border-color: red;

        option {
            color: black;
        }
    }
}

.first-step {
    .input-valid {
        &::placeholder {
            color: red;
        }

        color: red;
        border-color: red;
    }
}

.new-compaign-popup-content {
    overflow-x: hidden;
}

.toastCompaingPopup {
    .Toastify__close-button--light {
        margin-top: 0 !important;
    }

    .Toastify__toast-icon svg {
        margin-top: 0 !important;
    }

    .Toastify__toast-body {
        text-align: left;
    }
}

.new-compaign-popup__popup .first_popup_content .dropdown > .invalid-selector {
    & > div {
        border: 1px solid red !important;

        & > div {
            & > div:first-child {
                & > div:first-child {
                    color: red;
                }
            }
        }
    }
}

.new-compaign-popup__popup {
    .popup__widgets {
        height: 507px;
    }
}

.pods-table-btn-group {
    display: flex;

    .new-compaign-row:first-child {
        margin-right: 10px;
    }
}

.choosePodsMulti {
    & > div {
        & > div:nth-child(1) {
            height: 60px;
            flex-wrap: nowrap;

            & > div {
                min-width: auto;
                //background: #F2994A;
                //color: #fff;
            }
        }

        & > div:nth-child(2) {
            height: 60px;

            & > div:first-child {
                display: none;
            }

            & > span {
                display: none;
            }
        }
    }

    & > div:nth-of-type(2) {
        box-shadow: none !important;
        margin-top: 0 !important;
        border: 1px solid #eeeeee !important;
        border-top: 0 !important;

        & > div {
            height: 100% !important;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }

            & > div {
                text-align: left;
                border-bottom: 1px solid #eeeeee;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;

                /* Gray 2 */

                color: #4f4f4f;
                height: 45px;
            }
        }
    }
}

.css-1rhbuit-multiValue {
    color: #ffffff;
    border-radius: 6px !important;
    background-color: #f2994a !important;

    & > div {
        color: #fff;
    }
}

.css-xb97g8 {
    border-radius: 6px !important;
}

.css-xb97g8:hover {
    background-color: #f2994a !important;

    svg {
        path {
            fill: #fff;
            border-radius: 6px !important;
        }
    }
}

.uploadFiles-content-slider {
    max-width: 489px;
    min-height: 507px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    position: relative;

    .uploadFiles-content-slider-item-pods {
        position: absolute;
        min-width: 100%;

        &.active-zones {
            left: -838px;
        }
    }

    .uploadFiles-content-slider-item-zones {
        position: absolute;
        left: 838px;
        min-width: 100%;

        &.disable-pods {
            left: 0;
        }

        &.active-stores {
            left: -838px;
        }
    }

    .uploadFiles-content-slider-item-stores {
        position: absolute;
        left: 838px;
        min-width: 100%;

        &.disable-zones {
            left: 0;
        }

        .uploadFiles-content-slider-item-content {
            li {
                background-image: none;

                &:hover {
                    background-image: none;
                }
            }
        }
    }

    .uploadFiles-content-slider-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        padding: 10px 18px 10px 18px;

        span {
            margin-bottom: 0;
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #171b1e;
        }

        input {
            width: 229px;
            height: 34px;
            border-radius: 4px;

            &::placeholder {
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                color: #d7d7d7;
            }
        }
    }

    .uploadFiles-content-slider-item-content {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            overflow-y: auto;
            height: 450px;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }

            li {
                text-align: left;
                height: 40px;
                display: flex;
                align-items: center;
                padding-left: 18px;
                border-bottom: 1px solid #eeeeee;
                background-image: url("./np_arrow_2335663.svg");
                background-repeat: no-repeat;
                background-position: 97% center;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:last-child {
                    border-bottom: 0;
                }

                &:hover {
                    background-color: #f2994a;
                    color: #fff;
                    background-image: url("./np_arrow_2335663_fff.svg");
                }
            }
        }
    }
}

.uploadFiles-content-slider-item-stores-upload {
    display: none;

    &.active {
        display: flex;
        flex-direction: column;
        max-width: 489px;
        margin-left: 11px;
        width: 100%;
        border: 1px solid #eeeeee;
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        .uploadFiles-content-slider-item-stores-upload-header {
            display: flex;
            justify-content: space-between;
            padding: 10px 18px 10px 18px;
            border-bottom: 1px solid #eeeeee;

            span {
                margin-bottom: 0;
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: #171b1e;
            }

            button {
                width: 136px;
                height: 34px;
                border-radius: 4px;
                border: 0;
                background: #56ccf2;
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #ffffff;
            }
        }

        .uploadFiles-content-slider-item-stores-upload-content {
            height: 100%;

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                text-align: left;
                overflow-y: auto;
                height: 100%;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background-color: rgba(0, 0, 0, 0.5);
                    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                }

                li {
                    min-height: 40px;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;

                    & > span {
                        padding-left: 18px;
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 0;
                        background-image: url(./icon_bottom_arrow.svg);
                        background-repeat: no-repeat;
                        background-position: 97% center;
                        border-bottom: 1px solid #eeeeee;
                        font-family: Nunito Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 31px;
                        /* or 221% */

                        color: #171b1e;
                    }

                    ul {
                        padding: 0;
                        margin: 0;
                        list-style-type: none;
                        overflow-y: hidden;
                        transition: all 0.3s ease-in-out;
                        display: none;

                        &.active {
                            display: flex;
                            flex-direction: column;
                        }

                        li {
                            background-image: none;
                            border-bottom: 0;
                            display: flex;
                            padding-right: 18px !important;
                            justify-content: space-between;

                            .upload-navigation-bar {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                a {
                                    margin-right: 10px;
                                    zoom: 0.75;

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }

                            & > span {
                                font-family: Nunito Sans;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 31px;
                                color: #171b1e;
                                background-image: none;
                                border-bottom: 0;
                            }

                            .default-switch {
                                margin-left: 50%;

                                .MuiSwitch-root {
                                    margin-bottom: 0;

                                    & > span {
                                        margin-bottom: 0;

                                        & > span {
                                            margin-bottom: 0;

                                            & > span {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.content-upload-switch {
    min-height: 30px !important;
    height: 30px;
    justify-content: end;
    padding-right: 0 !important;

    .default-switch {
        padding-top: 5px;
        zoom: 0.75;

        .Mui-checked {
            .MuiSwitch-thumb {
                color: #1976d2;
            }
        }

        & > .MuiGrid-root {
            justify-content: end;
            padding-right: 15px;
        }
    }
}

.upload-content-container {
    &.active {
        display: flex;
        justify-content: center;
    }

    .uploadFiles-content-slider-upload {
        margin: 0;
    }
}

.uploadFiles-content-slider-item-stores-upload.active
    .uploadFiles-content-slider-item-stores-upload-content
    ul
    li
    ul
    li.content-upload-switch {
    padding-right: 0 !important;
    justify-content: end;
}

.view-navigation-bar {
    display: flex;
    //align-items: center;
    align-items: baseline;
    flex-direction: column;
    zoom: 0.85;
    //margin-top: 6px;
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    padding-left: 25px;
    padding-right: 25px;

    & > div {
        display: flex;

        span {
            display: flex;
            background: #c4c4c4;
            height: 19px;
            width: 25px;
            margin-right: 2px !important;
            margin-bottom: 2px !important;
            transition: all 0.3s ease-in-out;

            &:hover {
                background: #f2994a;
            }
        }
    }

    span {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 11px !important;
        line-height: 31px;
        color: #171b1e;
        background-image: none;
        border-bottom: 0;
        margin-bottom: 0 !important;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in-out;

    svg {
        transition: all 0.3s ease-in-out;

        path {
            transition: all 0.3s ease-in-out;
        }
    }

    &:hover {
        svg {
            path {
                fill: #f2994a;
            }
        }
    }
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 90px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -45px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.uploadFiles-content-slider-item-stores-upload-content {
    ul {
        overflow: hidden;
    }
}

.uploadFiles-content-slider-item-stores .uploadFiles-content-slider-item-content ul li.active-item {
    background-color: #f2994a;
    color: #fff;
}

.upload-new-directory {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.upload-new-directory_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
    width: 554px;
    height: 215px;
    border-radius: 10px;
    padding: 20px;

    h6 {
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 25px !important;
        color: #171b1e !important;
        text-align: left !important;
    }

    p {
        font-size: 15px !important;
        line-height: 20px !important;
        color: #717579 !important;
        text-align: left !important;
        margin-bottom: 10px !important;
    }

    .default-switch {
        .MuiSwitch-thumb {
            color: #efefef !important;
        }

        .MuiSwitch-track {
            background-color: #5c5b5b !important;
        }

        .MuiGrid-item {
            font-size: 15px;
            line-height: 20px;
            color: #717579;
        }
    }

    .upload-new-directory_btn_group {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;

        button {
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 0.8;
            }

            &:first-child {
                background: transparent;
                border: 1px solid #eeeeee;
                box-sizing: border-box;
                border-radius: 4px;
                width: 108px;
                height: 34px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #2f80ed;
                margin-right: 7px;
            }

            &:last-child {
                background: #56ccf2;
                border: 1px solid #56ccf2;
                box-sizing: border-box;
                border-radius: 4px;
                width: 108px;
                height: 34px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #fff;
                margin-right: 7px;
            }
        }
    }
}

.uploadFiles-content-slider-item-stores-upload-content-create-new-file {
    width: 136px;
    height: 34px;
    border-radius: 4px;
    border: 0;
    background: #56ccf2;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 22px;
    text-align: center;
    color: #ffffff !important;
    margin-left: 17px !important;
    text-align: center;
    display: flex;
    padding: 0 !important;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    &:hover {
        filter: contrast(1.2);
    }
}

.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-header button {
    transition: all 0.3s ease-in-out;

    &:hover {
        filter: contrast(1.2);
    }
}

.upload-new-directory_inner-create-file {
    height: 260px;

    .default-switch {
        margin-left: 0 !important;
    }
}

.upload-new-directory_inner-create-file-btn {
    display: flex;
    align-items: center;
    width: 387px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #eeeeee;
    justify-content: space-between;

    span {
        margin-bottom: 0 !important;
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 25px !important;
        color: #171b1e !important;
        padding-left: 16px;
    }

    button {
        width: 119px;
        height: 49px;
        left: 262px;
        top: 5px;
        border: 0;
        background: #09bd3c;
        border-radius: 8px;
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 25px !important;
        color: #ffffff !important;
        margin-right: 5px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            filter: contrast(1.2);
        }
    }
}

.upload-new-directory_inner-create-file-carousel {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    display: none;

    &.active {
        display: flex;
    }

    svg {
        margin-top: 7% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .upload-new-directory_inner-create-file-carousel-col {
        display: flex;
        margin-right: 25px;

        .upload-new-directory_inner-create-file-carousel-col-file-group {
            display: flex;
            flex-direction: column;

            span:first-child {
                display: flex;
                background: #c4c4c4;
                width: 112px;
                height: 113px;
                margin-bottom: 0;
                opacity: 0.34;
            }

            span:last-child {
                margin-bottom: 0;
                margin-top: 3px;
                text-align: left;
                font-weight: 600;
                font-size: 10px;
                line-height: 14px;
                color: #000000;
            }

            &:last-child {
                span:first-child {
                    display: flex;
                    background: #c4c4c4;
                    width: 112px;
                    height: 113px;
                    margin-bottom: 0;
                    opacity: 1;
                }
            }
        }
    }
}

.upload-new-directory_inner-create-file-carousel-row {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    margin-right: 0 !important;

    .upload-new-directory_inner-create-file-carousel-col-file-group {
        flex-direction: row !important;
        align-items: center;

        span:first-child {
            height: 40px !important;
        }

        span:last-child {
            margin-left: 10px;
        }

        &:first-child {
            span:first-child {
                background-color: #c4c4c4;
                opacity: 0.34;
            }
        }

        &:nth-child(2) {
            span:first-child {
                background-color: #c4c4c4;
                opacity: 1;
            }
        }

        &:last-child {
            span:first-child {
                background-color: #a3a2a2 !important;
                opacity: 1;
            }
        }
    }
}

.upload-new-directory_inner-create-file {
    .default-switch {
        .Mui-checked {
            .MuiSwitch-thumb {
                color: #1976d2 !important;
            }
        }
    }
}

.upload-file-swtich .MuiSwitch-track.active-thumb {
    background-color: #1976d2 !important;
    opacity: 0.34;
}

#selectPodHidden {
    //display: none;
}

.uploadFiles-content-slider-item-stores-upload.active
    .uploadFiles-content-slider-item-stores-upload-content
    > ul
    > li
    > span::after {
    margin-left: auto;
    margin-right: 20px;
    transition: all 0.3s ease-in-out;
}

.rotate-icon {
    &::after {
        transform: rotate(180deg);
    }
}

.view-navigation-bar {
    justify-content: end;
}

.uploadFiles-content-slider-item-stores-upload.active
    .uploadFiles-content-slider-item-stores-upload-content
    ul
    li
    ul
    li
    > span {
    border-bottom: 0 !important;
}

.breadcrumbs {
    width: 489px;
    max-width: 489px;
    display: flex;
    justify-content: left;
    margin: 0 auto !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #171b1e !important;
    margin-bottom: 10px !important;

    &-last {
        justify-content: center;
    }
}

.upload-content-container {
    display: flex;
    flex-direction: column;

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content > ul {
    height: 450px;
}

.uploadFileIconMulti {
    background-image: url("./Vector-stores.svg");
    width: 20px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 0 !important;
    padding-left: 20px;
    margin-left: auto !important;
    transition: all 0.3s ease-in-out;
}

.uploadFiles-content-slider-item-stores {
    ul {
        li {
            &:after {
                content: url("./Vector-stores.svg");
                background-repeat: no-repeat;
                margin-left: auto;
                //padding-right: 20px;
                display: flex;
                right: 15px;
                top: 3px;
                position: relative;
                transition: all 0.3s ease-in-out;
            }
            &.active-item {
                &:after {
                    content: url("./Vector-stores-fff.svg");
                    background-repeat: no-repeat;
                    transform: rotate(45deg);
                    right: 18px;
                    //top: 7px;
                    //right: 5px;
                }
            }
        }
    }
}

.uploadFiles-content-slider-item-stores {
    .uploadFiles-content-slider-item-content {
        ul {
            overflow-x: hidden !important;
        }
    }
}

.next-btn-slide-second {
    display: none !important;
}

.multiSelectedActive {
    ul {
        li {
            ul {
                li {
                    display: none !important;

                    &:last-child {
                        margin-top: 10px !important;
                        display: flex !important;
                    }
                }
            }
        }
    }
}

.upload-new-directory_inner.upload-new-directory_inner-create-file {
    .file-upload {
        display: flex;
        align-items: center;
        max-width: 387px;
        height: 60px;
        border: 1px solid #eeeeee;
        border-radius: 10px;

        label {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #171b1e;
            width: 100%;
            margin-left: 16px;
            cursor: pointer;
        }

        input {
            border: 0 !important;
            opacity: 0;
            position: absolute;
            z-index: -1;
        }

        button {
            min-width: 119px;
            height: 49px;
            border: 0;
            background-color: #09bd3c;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #ffffff;
            margin-left: auto;
            margin-right: 6px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                filter: contrast(1.2);
            }

            &.disabled {
                background: #a6a6a6;
                cursor: not-allowed;
            }
        }
    }
}

#chooseYearsMultiInputs {
    display: none;
}

.uploadFiles-content-slider-item-stores-upload.active {
    max-width: 550px !important;
}

.disabled-scroll {
    overflow: hidden;
}

.file-upload label {
    text-decoration: none;

    span {
        margin: 0;
        text-align: left;
        width: 64px;
        transition: all 0.3s ease-in-out;
        border-bottom: 1px solid transparent;

        &:hover {
            color: #56ccf2 !important;
            border-bottom: 1px solid #56ccf2;
            //text-decoration: underline;
        }
    }
}

.btn__compaing__upload__files {
    //stroke: #fff !important;
    svg {
        path {
            fill: transparent !important;
        }
    }

    &:hover {
        svg {
            path {
                fill: transparent !important;
                stroke: #fff !important;
            }
        }
    }
}

.createNewSection {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;

    &::placeholder {
        color: #d7d7d7;
    }
}

.file-upload {
    label {
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #171b1e;
            min-width: 78px;
        }
    }
}

.choosePodsMulti {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #171b1e;
}

.uploadFiles-content-slider-item-content {
    ul {
        li {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 31px;
            color: #171b1e;
        }
    }
}

.first_popup_content p.select-file-upload-text {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;

    /* Primary Text */
    color: #171b1e;
}

.choosePodsMulti {
    width: 480px;
}

.new-compaign-popup__popup__file__upload {
    .first-step {
        max-width: 480px !important;
    }
}

.choosePodsMulti > div > div {
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0);
        border-radius: 16px;
        border: 5px solid #fff;
    }

    &:hover::-webkit-scrollbar-thumb {
        background-color: #a0a0a5;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
        border: 4px solid #f4f4f4;
    }

    &:nth-child(1) {
        overflow-x: auto;

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0);
            border-radius: 16px;
            border: 5px solid #fff;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: #a0a0a5;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #a0a0a5;
            border: 4px solid #f4f4f4;
        }
    }
}

.upload-new-directory_inner-create-file-carousel-row
    .upload-new-directory_inner-create-file-carousel-col-file-group
    span:first-child {
    height: 37.5px !important;
}

.upload-new-directory_inner-create-file {
    .default-switch {
        position: relative;
        right: 8px;
        margin-top: 10px !important;

        &.upload-file-switch {
            margin-top: 0 !important;
        }
    }
}

.upload-new-directory_inner-create-file-carousel {
    svg {
        &:first-child {
            position: relative;
            right: 9px;
        }

        &:last-child {
            position: relative;
            left: 9px;
        }
    }
}

.tooltip .tooltiptext {
    margin-left: -70px;

    &::after {
        margin-left: 20px;
    }
}

.uploadFiles-content-slider.uploadFiles-content-slider-upload .uploadFiles-content-slider-item-header {
    height: 54px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 0;
    padding-bottom: 0;
}

.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-header {
    min-height: 54px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
}

.choosePodsMulti > div:nth-of-type(2) {
    border-radius: 0 !important;
}

.choosePodsMulti > div:nth-of-type(2) > div {
    padding-top: 0;
    border-top: 1px solid hsl(0, 0%, 93%);

    &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
        //border: 1px solid red;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display: none;
    }
}

.choosePodsMulti > div:nth-of-type(2) > div > div {
    border-right: 1px solid hsl(0, 0%, 93%);
    display: flex;
    align-items: center;

    &:hover {
        color: #fff;
    }

    &:last-child {
        border-bottom: 0;
    }
}

.choosePodsMulti > div:nth-of-type(2) {
    border-top: 0 !important;
    border-radius: 0;
    top: 53px;
}

.choosePodsMulti > div:nth-of-type(2) > div {
    padding-bottom: 0;
}

.DataTable__2022-2023 {
    display: none;
    &.active {
        display: block;
    }
}

.DataTable__2021-2022 {
    display: none;
    &.active {
        display: block;
    }
}

.choosePodsMulti > div > div:nth-child(1) > div {
    text-align: left;
}

#popup-root {
    .popup-content {
        height: 95% !important;
    }
}

.choosePodsMulti {
    margin-bottom: 10px;
}

.interestingSecondTimePeriod {
    display: none;
}

.hiddenNotifyBtnPasswordFourth,
.hiddenNotifyBtnPasswordSecond,
.hiddenNotifyBtnPasswordThird {
    display: none;
}

.hiddenNotifyBtnPasswordFourth,
.switch-all-store {
    display: none;
}

.uploadFiles-content-slider-item-stores-upload.active
    .uploadFiles-content-slider-item-stores-upload-content
    ul
    li
    ul
    li
    .upload-navigation-bar
    a:last-child {
    margin-right: 0;
}

.uploadFiles-content-slider-item-stores-upload.active
    .uploadFiles-content-slider-item-stores-upload-content
    ul
    li
    ul
    li
    .upload-navigation-bar
    a {
    zoom: 0.75;
}

.upload-files-btn {
    display: flex;
    align-items: center;
    background: #c4c4c4;
    color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.upload-files-btn svg {
    margin-right: 5px;
    transition: all 0.2s ease-in-out;
}

.new-compaign-popup-content svg {
    display: block !important;
    margin-left: auto;
    cursor: pointer;
}

.upload-files-btn svg path {
    fill: #fff;
    transition: all 0.2s ease-in-out;
}

.upload-navigation-bar {
    a {
        &:first-child {
            display: none !important;
        }
        &:nth-child(2) {
            display: none !important;
        }
        &:nth-child(3) {
            display: none !important;
        }
    }
}

.upload-new-directory {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.validationDirectoryTrue,
.validationDirectoryFalse,
.validationDirectoryFalseWhiteSpace {
    display: none;
}

.upload-new-directory_inner {
    height: 255px;
}

.third_level_popup_content {
    align-items: flex-start;
    justify-content: flex-start;
    height: 90%;
    padding-top: 21px;

    h5,
    h6,
    p {
        margin: 0;
    }

    hr {
        width: 643px;
        height: 0px;
        left: 24px;
        top: 69px;
        border: 1px solid #e8e8e8;
        margin-top: 17px;
        margin-bottom: 10px;
        margin-left: 0;
    }

    h5 {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: #171b1e;
    }

    h6 {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #171b1e;
    }

    p {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #717579;
    }

    .cancel-btn {
        width: 108px;
        height: 34px;
        background: #56ccf2;
        border-radius: 4px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;

        color: #ffffff;
        margin-top: auto;
    }
}

.third_level_popup_content_group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
}

.third_level_popup_content_group {
    .create_new_section_container_directory {
        width: 100%;

        #valueUploadDir {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 14px;

            .valueUploadDirItemRow {
                display: flex;
                align-items: center;
                height: 45px;

                .valueUploadDirItemFile {
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 20px;
                    color: #717579;
                    width: 30%;
                    text-align: left;
                    margin-bottom: 0;
                    padding-bottom: 8px;
                }

                .valueUploadDirItem {
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 20px;
                    color: #171b1e;
                    margin-bottom: 0;
                    padding-bottom: 8px;
                }
            }
        }
    }
}

.new-compaign-row {
    margin-right: 20px;
}

.podsDropdown {
    height: 60px;
    width: 480px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #171b1e;
}

.new-compaign-popup-content .new-compaign-popup__popup .first_popup_content .fourth-step--new-compaing {
    max-width: 900px !important;

    .dropdowLocationStep {
        width: 387px;
        height: 60px;
        display: flex;
        justify-content: flex-start;
        margin-left: 0;
    }

    .popup__widgets .widget__single .widget__pod__info {
        padding-left: 11px;
        padding-right: 11px;
    }
    .widget__pod__info {
        border-bottom: 1px solid #eeeeee;
    }
    .location-list-items {
        padding-top: 13px;
    }
    .widget__pod__info__successes {
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        .widget__pod__info__successes--search {
            width: 70%;
            margin-right: 21px;
            display: flex;
            align-items: center;
            h5 {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                /* identical to box height */

                /* Primary Text */

                color: #171b1e;
            }
            input {
                background: #ffffff;
                height: 34px;
                border: 1px solid #eeeeee;
                border-radius: 4px;
                padding-left: 12px;
                box-sizing: border-box;
            }
        }
        .widget__pod__info__successes--btn {
            background: #f2994a;
            border-radius: 4px;
            width: 108px;
            height: 34px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            text-decoration: none;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            &:hover {
                background: #fa3535;
            }
        }
        .widget__pod__info__successes--btn--blue {
            background: #56ccf2;
        }
    }
}

.new-compaign-popup-content .new-compaign-popup__popup .sixth-step__compaing {
    max-width: 960px !important;
    .popup__widgets {
        .widgets-col {
            width: calc(33.33%);
            .widget__pod__info__successes---test {
                display: flex;
                align-items: center;
                justify-content: space-between;
                h5 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    padding-left: 11px;
                    /* identical to box height */

                    /* Primary Text */

                    color: #171b1e;
                }
                a {
                    background: #56ccf2;
                    border-radius: 4px;
                    width: 108px;
                    height: 34px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    text-decoration: none;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        background: #fa3535;
                    }
                }
            }
            .widget__pod__info {
                padding-top: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #eeeeee;
                .widget__pod__info__successes--search {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    box-sizing: border-box;
                    span {
                        margin-right: 15px;
                    }
                    input {
                        width: 60%;
                        height: 40px;
                    }
                    span {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        color: #171b1e;
                        text-align: left;
                        padding-left: 11px;
                    }
                }
            }
            .testMarketsLists {
                padding-left: 0;
                li {
                    border-bottom: 1px solid #eeeeee;
                    padding-left: 18px;
                    padding-right: 18px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
        }
    }
}

.popup__success__six-steps--new-compaing {
    margin-top: 60px;
    display: flex !important;
    align-items: flex-start !important;
    .button-group-last {
        margin: inherit;
        margin-top: 40px;
    }
}