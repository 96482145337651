@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");
@import 'react-toastify/dist/ReactToastify.css';

@font-face {
  font-family: "National2";
  src:  url('../fonts/national2-regular-webfont.woff2') format('woff2'),
        url('../fonts/national2-regular-webfont.woff') format('woff'),
        url('../fonts/National2-Regular.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
}

html,
body {
  height: 100%;
  margin: 0;
}

ul {
  list-style-type: none;
}

body {
  background-color: #f5f5f5;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.wrapper {
  flex: 1 1 auto;
  display: flex;
  margin-top: 20px;
  &__auth {
    padding: 0 50px;
    margin-top: 0;
    @media (max-width: 1440px) {
      padding: 0 35px;
    }
    @media (max-width: 1024px) {
      padding: 0 25px;
    }
    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }
}

.rdt_Ta§leCol {
  &:hover span,
  &:focus span,
  &:hover span.__rdt_custom_sort_icon__ *,
  &:focus span.__rdt_custom_sort_icon__ * {
    opacity: 1 !important;
  }
  & > div {
    span {
      margin-left: 8px;
      svg {
        height: 30px !important;
        opacity: 1 !important;
      }
    }
  }
}

.rdt_TableCol,
.rdt_TableCell {
  input[type="checkbox" i] {
    width: 17px;
    height: 17px;
  }
}

.custom-sort-icons {
  display: flex;
}

.rdt_TableHeadRow {
  padding-left: 25px;
}

.rdt_TableRow {
  &:hover {
    background-color: #fa3535;
    .hover-white {
      color: #fff !important;
    }
    .stores__layout .col:first-child div span {
      background-color: #fff;
    }
    .stores__layout .col:nth-child(2) div span {
      background-color: #fff;
    }
    .stores__layout .col:nth-child(3) div:first-child span:first-child {
      background-color: #fff;
    }
    .stores__layout .col:nth-child(3) div:last-child span:first-child {
      background-color: #fff;
    }
    .stores__layout .col:nth-child(4) span:first-child {
      background-color: #fff;
    }
    .stores__layout .col:nth-child(5) div:first-child span:first-child {
      background-color: #fff;
    }
    .stores__layout .col:nth-child(5) div:last-child span:first-child {
      background-color: #fff;
    }
  }
}

.btn-save {
  width: 115px;
  height: 35px;
  background: #fa3535;
  border-radius: 4px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  color: #fff;
  border: 1px solid #fa3535;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  @media (max-width: 420px) {
    width: 100px;
  }
  &:hover {
    color: #fff;
    background-color: #f00d0d;
    border: 1px solid #f00d0d;
  }
}

.btn-cancel {
  width: 115px;
  height: 35px;
  background: transparent;
  border-radius: 4px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  color: #2f80ed;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  @media (max-width: 420px) {
    width: 100px;
  }
  &:hover {
    color: #fff;
    background: #2f80ed;
    border: #2f80ed;
  }
}

.popup-content {
  height: 100%;
  hr {
    margin: 6px 0;
    border: 1px solid #ccc;
  }
  .file-details {
    font-size: 14px;
    font-family: "Mulish";
  }
  label.upload {
    display: block;
    border: 1px solid #a4a4a4;
    border-radius: 4px;
    background: transparent;
    font-family: "Mulish";
    font-weight: 700;
    font-size: 11px;
    color: #4f4f4f;
    height: 36px;
    cursor: pointer;
    width: 100px;
    padding-top: 10px;
    text-align: center;
    &:hover {
        color: #fff;
        background: #fa3535;
        border-color: #fa3535;
    }
  }
  .button-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    &.end {
      justify-content: flex-end;
    }
  }
  button.common {
    border: 1px solid #a4a4a4;
    border-radius: 4px;
    background: transparent;
    font-family: "Mulish";
    font-weight: 700;
    font-size: 11px;
    color: #4f4f4f;
    height: 36px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100px;
    margin-top: 6px;
    &[disabled] {
      background-color: #ccc;
      &:hover {
        background-color: #ccc;
        border-color: #a4a4a4;
      }
    }
    &:hover {
        color: #fff;
        background: #fa3535;
    }
    &.strong {
      color: #fff;
        background: #fa3535;
        &:hover {
          color: #4f4f4f;
          background: #ccc;
          border-color: #fa3535;
      }
    }
  }
  

  .popup__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
    h3 {
      margin-top: 22px;
      font-family: "Mulish";
      font-weight: 700;
      font-size: 25px;
      line-height: 31px;
      color: #333333;
    }
    p {
      font-family: "Mulish";
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #828282;
      margin-top: 3px;
    }
    .btn-group {
      margin-top: 0;
      position: absolute;
      bottom: 2px;
      button {
        &:first-child {
          margin-right: 5px;
        }
        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }
}

.btn-submit {
  height: 35px;
  width: 115px;
  color: #fff;
  background: #fa3535;
  border: 1px solid #fa3535;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background: transparent;
    color: #fa3535;
  }
}

.delete-link {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-decoration-line: underline;
  color: #2f80ed;
  border: 0;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    color: #f40027;
  }
}

.popup__delete {
  text-align: center;
  h3 {
    font-family: "Mulish";
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    color: #333333;
  }
  p {
    font-family: "Mulish";
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #828282;
  }
  input {
    width: 100%;
    margin: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    height: 36px;
    padding: 0 10px;
  }
  .btn-group {
    button {
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }
    }
  }
}

.preloader {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  #loader {
    display: block;
    position: relative;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fa3535;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
      margin: -50px 0 0 -50px;
    }
    @media (max-width: 425px) {
      width: 75px;
      height: 75px;
      margin: -37.5px 0 0 -37.5px;
    }
  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fa3535;
    animation: spin 3s linear infinite;
  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fa3535;
    animation: spin 1.5s linear infinite;
  }
  @-webkit-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.custom-sort-icons {
  svg {
    opacity: 1 !important;
  }
}

.btn {
  width: 208px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid #cacaca;
  background: #fff;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #717579;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #fff;
    background: #F40027;
  }
}
