.header__logo {
    min-width: 345px;
    max-width: 345px;
    a {
        display: block;
        min-width: 110px;
        max-width: 110px;
        margin-left: 72px;
    }
    @media screen and (max-width: 1100px) {
        min-width: 110px;
        max-width: 110px;
    }
    @media screen and (max-width: 960px) {
        min-width: auto;
        width: auto;
        margin-right: 40px;
    }
    @media screen and (max-width: 900px) {
        margin-right: 0;
    }
}