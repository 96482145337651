.file-upload {
    h5 {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #171b1e;
        text-align: left;
    }
    hr {
        border: 0;
        height: 1px;
        background: #eee;
        margin: 10px 0;
    }
    p {
        font-size: 15px;
        line-height: 20px;
        color: #717579;
        text-align: left;
    }
    .field {
        margin-bottom: 10px;
        h6 {
            font-weight: 600;
            font-size: 16px;
            color: #171b1e;
            text-align: left;
            margin-bottom: 5px;
        }
        input[type=text] {
            width: 100%;
            display: flex;
            align-items: center;
            border: 1px solid #eee;
            border-radius: 10px;
            height: 40px;
            box-sizing: border-box;
            padding: 10px;
            padding-left: 15px;
            outline: none;
            font-size: 16px;
            color: #171b1e;
            &.invalid {
                border: 1px solid #fb6d3b;
                &::placeholder {
                    color: #fb6d3b;
                }
            }
            &::placeholder {
                color: #171b1e;
            }
            &.ReactTags__tagInputField {
                background-color: transparent;
            }
        }
        input[type=checkbox] + label {
            margin-left: 5px;
            color: #717579;
        }
        .react-tags-wrapper {
            height: 100px;
        }
    }
}

.file-input {
    display: flex;
    align-items: center;
    text-align: left;
    background: #fff;
    padding: 10px;
    padding-left: 15px;
    width: 450px;
    position: relative;
    border: 1px solid #eee;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    &.invalid {
        border: 1px solid #fb6d3b;
        label {
            color: #fb6d3b;
        }
    }
    [type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
        cursor: pointer;
    }
    label {
        font-size: 16px;
        color: #171b1e;
    }
}

.upload-btn {
    width: 100%;
    max-width: 200px;
    height: 49px;
    border: 0;
    background-color: #09bd3c;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        filter: contrast(1.5);
    }
}

.file-container {
    display: flex;
    justify-content: space-between;
    .file-preview {
        width: calc(58%);
        img, iframe {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
    form {
        width: calc(50% - 80px);
    }
}