.briefing-templates-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.briefing-templates {
  background: #fff;
  padding: 36px 33px;
  padding-bottom: 50px;
  box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 20px;
  @media (max-width: 1500px) {
    padding: 20px;
    padding-bottom: 50px;
  }
  & > .btn-group {
    display: flex;
    margin-top: 80px;
    .btn-save {
      background: #fade57;
      border-radius: 4px;
      border: 0;
      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #4f4f4f;
      width: 116px;
      display: flex;
      height: 36px;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        filter: contrast(1.2);
      }
    }
    .btn-cancel {
      background: transparent;
      border-radius: 4px;
      border: 0;
      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #2f80ed;
      width: 116px;
      display: flex;
      height: 36px;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        filter: contrast(1.2);
      }
    }
    .btn-submit {
      background: #fa3535;
      border-radius: 4px;
      border: 0;
      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #fff;
      width: 116px;
      display: flex;
      height: 36px;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        filter: contrast(1.2);
      }
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1357px) {
      display: flex;
      flex-wrap: wrap;
    }
    .col {
      width: calc(33.33% - 20px);
      @media (max-width: 1357px) {
        width: calc(50% - 20px);
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      h3 {
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;
        color: #333333;
        @media (max-width: 1700px) {
          font-size: 21px;
        }
        @media (max-width: 1550px) {
          font-size: 19px;
        }
        @media (max-width: 1357px) {
          font-size: 17px;
        }
      }
      p {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

        /* Gray 3 */

        color: #828282;
      }
      hr {
        border: 0;
        height: 1px;
        background-color: #e0e0e0;
        margin-top: 13px;
        margin-bottom: 10px;
      }
      .details {
        h5 {
          font-family: "Mulish";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #333333;
          margin-bottom: 8px;
        }
        p {
          font-family: "Mulish";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #4f4f4f;
          span {
            color: #fbde57;
          }
        }
        input[type="text"],
        input[type="date"] {
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          font-family: "Mulish";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          height: 36px;
          color: #333333;
          padding: 0 10px;
          width: 100%;
          max-width: 379px;
          box-sizing: border-box;
          margin-bottom: 11px;
          margin-top: 2px;
          @media (max-width: 767px) {
            max-width: 100%;
          }
          &.invalid {
            border-color: #fa3535;
            color: #fa3535;
            &::placeholder {
              color: #fa3535;
            }
          }
        }
        textarea {
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          width: 100%;
          height: 146px;
          padding: 10px;
          box-sizing: border-box;
          resize: none;
          &.invalid {
            border-color: #fa3535;
            color: #fa3535;
            &::placeholder {
              color: #fa3535;
            }
          }
        }
      }
    }
  }
}

.details-list {
  .checbox-container {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 13px;
    }
    .checbox-group {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark::after {
          display: block;
        }
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 19px;
        width: 19px;
        border: 1px solid #bdbdbd;
        border-radius: 100%;
        &::after {
          content: "";
          position: absolute;
          display: none;
          left: 1px;
          top: 1px;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          background: #bdbdbd;
          box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.25);
        }
      }
      &:hover input ~ .checkmark {
        &::after {
          display: block;
        }
      }
    }
  }
}

.details-list-heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 7px;
}
