.store {
    &__section {
        h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            color: #171b1e;

            span {
                font-weight: 400;
            }
        }

        hr {
            border: 0;
            margin-top: 60px;
            margin-bottom: 65px;
            height: 1px;
            background: #9d9d9d;
        }

        .row {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
            grid-gap: 25px;
            align-items: stretch;

            .col {
                //margin-right: 25px;
                display: flex;
                overflow: hidden;

                &:last-of-type {
                    margin-right: 0;
                }

                h6 {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #000000;
                    //position: absolute;
                    //bottom: 0;
                    z-index: 999;
                }

                div {
                    width: inherit;
                    overflow: hidden;
                    box-sizing: border-box;
                    //position: relative;
                    cursor: pointer;

                    &:nth-child(2),
                    &:nth-child(3) {
                        h6 {
                            display: none;
                        }
                    }

                    .file-container {
                        position: relative;
                        width: inherit;
                        height: auto;

                        img {
                            box-sizing: border-box;
                            width: inherit;
                            object-fit: cover;
                            pointer-events: none;
                        }

                        video {
                            width: inherit;
                            object-fit: cover;
                            pointer-events: none;
                        }
                    }
                }

                .file-container {
                    position: relative;
                    width: inherit;

                    img {
                        box-sizing: border-box;
                        width: inherit;
                        object-fit: cover;
                        pointer-events: none;
                    }

                    video {
                        width: inherit;
                        object-fit: cover;
                        pointer-events: none;
                    }

                    .crud-btn {
                        position: absolute;
                        right: 8px;
                        top: 8px;
                        background: transparent;
                        border: 0;
                        padding: 0;
                        cursor: pointer;

                        svg {
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}

.crud-btn-popup {
    display: none;
    flex-direction: column;
    width: 202px;
    background: #fff;
    position: absolute;
    top: -15px;
    right: 52px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;

    &::before {
        content: url("./popup-arrow.svg");
        position: absolute;
        right: -29px;
        top: 25px;
    }

    &.active {
        display: flex;
    }

    button {
        height: 46px;
        border: 0;
        background: transparent;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
        text-align: left;
        padding-left: 17px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: rgba(250, 166, 53, 0.5);
        }
    }
}

.store-content__popup-overlay {
    opacity: 1 !important;
    background: rgba(0, 0, 0, 0.3) !important;
}

.store-content__popup__content {
    padding: 38px 177px 60px 44px;
    position: relative;
    box-sizing: border-box;

    & > h5 {
        font-weight: 400;
        font-size: 26px;
        line-height: 35px;
        color: #171b1e;
    }

    & > p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #171b1e;
        margin-bottom: 10px;
    }

    .btn-close {
        border: 0;
        background: transparent;
        padding: 0;
        position: absolute;
        top: 17px;
        right: 16px;
        outline: none;
        cursor: pointer;

        svg {
            display: flex;
        }
    }

    .row {
        display: flex;

        .col {
            position: relative;

            .crud-file {
                position: absolute;
                top: 8px;
                right: 8px;
                cursor: pointer;
                background: transparent;
                border: 0;
                padding: 0;

                svg {
                    pointer-events: none;
                    display: flex;
                }
            }

            .download-file {
                position: absolute;
                bottom: 12px;
                right: 12px;
                height: 36px;
                width: 143px;
                background: #2f80ed;
                border-radius: 4px;
                font-family: "Mulish", sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 11px;
                line-height: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fbfbfb;
                cursor: pointer;
                outline: none;
                text-decoration: none;
                transition: all 0.2s ease-in-out;

                &:hover {
                    filter: contrast(1.2);
                }
            }

            &:first-of-type {
                margin-right: 30px;
            }

            img {
                max-width: 934px;
                max-height: 717px;
            }

            h6 {
                font-family: "Mulish", sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #333333;
            }

            p {
                font-family: "Mulish", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
            }

            hr {
                margin-top: 9px;
                margin-bottom: 26px;
                border: 0;
                height: 1px;
                background: #e0e0e0;
            }

            .btn-group button {
                font-family: "Mulish", sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 11px;
                line-height: 14px;
                color: #4f4f4f;
                border: 0;
                background: transparent;
                width: 116px;
                height: 36px;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    filter: contrast(1.2);
                }
            }

            .btn-group {
                margin-top: 13px;
            }

            .btn-group button.btn-save {
                background: #fade57;
                border-radius: 4px;
            }

            .btn-group button.btn-cancel {
                color: #2f80ed;
            }
        }
    }
}

.store__section__row {
    display: flex;
}

.menu-boards--last-night,
.menu-boards--day-time,
.drive-through--last-night,
.drive-through--day-time {
    display: none !important;

    &.active {
        display: grid !important;
    }
}

.three-cards {
    flex-direction: column;

    img,
    video {
        height: 100% !important;
    }
    h6 {
        display: none;
    }
    & > div {
        &:nth-child(3) {
            img,
            video {
                height: calc(100% - 20px) !important;
            }
            h6 {
                display: flex !important;
            }
        }
    }
}

.menu-boards--day-time .col.double:nth-child(2) {
    & > div:first-child {
        width: 49%;
        h6 {
            position: absolute;
        }
        .file-container {
            width: 100%;
        }
    }
}

.menu-boards--day-time .col.double:nth-child(3) {
    flex-direction: row-reverse;
    & > div:first-child {
        width: 49%;
        h6 {
            display: none;
        }
        .file-container {
            width: 100%;
        }
    }
    & > div:last-child {
        h6 {
            display: flex;
        }
    }
}

.store-content__popup__content {
    img,
    video {
        max-width: 934px;
        max-height: 717px;
    }
}

.three-cards {
    height: calc(100% - 24px);
    h6 {
        position: absolute;
    }
}

.store__section--drive-through .store__section__row {
    h4:nth-child(2) {
        display: none;
    }
}

.store {
   .MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: auto;
    padding-right: 10px;
   }
}

.store__section .row .col {
    width: 100%;
}

.default-switch .MuiSwitch-colorSecondary.Mui-checked {
    color: #1976d2;
}

.default-switch .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #1976d2!important;
}