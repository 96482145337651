.add-edit-stores__media-configuration {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    box-sizing: border-box;
    background: #f2f2f2;
    border-radius: 8px;
    height: 881px;
    h3 {
        font-family: "Mulish";
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
    }
    p {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
    }
    hr {
        border: 0;
        background: #e0e0e0;
        height: 1px;
        margin-top: 9px;
        margin-bottom: 22px;
    }
    h4 {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        padding-top: 14px;
        padding-bottom: 14px;
        margin-top: 17px;
    }
    h5 {
        font-weight: 700;
        font-size: 15px;
        color: #171b1e;
        margin-top: 17px;
    }
    &__btn {
        &-group {
            display: flex;
            margin-top: 36px;
            @media only screen and (max-width: 767px) {
                flex-wrap: wrap;
            }
        }
        &--save {
            width: 116px;
            height: 36px;
            border-radius: 4px;
            background: #fade57;
            color: #4f4f4f;
            font-family: "Mulish";
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            cursor: pointer;
            border: 0;
            transition: all 0.2s ease-in-out;
            @media only screen and (max-width: 767px) {
                width: 50%;
            }
            &:hover {
                filter: contrast(1.2);
            }
        }
        &--cancel {
            width: 116px;
            height: 36px;
            border-radius: 4px;
            background: transparent;
            color: #2f80ed;
            font-family: "Mulish";
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            cursor: pointer;
            border: 0;
            transition: all 0.2s ease-in-out;
            @media only screen and (max-width: 767px) {
                width: 50%;
            }
            &:hover {
                filter: contrast(1.2);
            }
        }
        &--delete {
            width: 116px;
            height: 36px;
            border-radius: 4px;
            background: #ff0000;
            color: #fff;
            font-family: "Mulish";
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            cursor: pointer;
            border: 0;
            transition: all 0.2s ease-in-out;
            margin-left: auto;
            @media only screen and (max-width: 767px) {
                width: 50%;
                margin-top: 15px;
                margin-left: 0;
            }
            &:hover {
                filter: contrast(1.2);
            }
        }
    }
}

.save-store__container {
    background: #fff;
    border-radius: 4px;

    h6 {
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: #171b1e;
        text-align: left;
    }

    hr {
        border: 0;
        background: #e8e8e8;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p {
        font-size: 15px;
        line-height: 20px;
        color: #717579;
        text-align: left;
        margin-bottom: 10px;
    }

    #zoneTitle {
        font-family: "Mulish";
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #4f4f4f;
        margin-bottom: 5px;
        outline: none;
        background: #ffffff;
        width: 100%;
        height: 40px;
        padding-left: 20px;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        border-radius: 10px;
    }

    button {
        background: #56ccf2;
        border: 1px solid #56ccf2;
        box-sizing: border-box;
        border-radius: 4px;
        width: 108px;
        height: 34px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #fff;
        margin-right: 7px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
}

.save-store-details {
    width: 100%;
    border-top: 1px solid #eeeeee;
    box-sizing: border-box;
    padding-top: 25px;

    p {
        margin-bottom: 0;
    }

    hr {
        border: 0;
        height: 1px;
        width: 100%;
        background: #e8e8e8;
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__user {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 10px;
        padding-bottom: 10px;

        h6 {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #717579;
            width: 220px;
        }

        p {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: #171b1e;
            margin-bottom: 0;
        }
    }

    &__pod-zone {
        display: flex;
    }

    &.active {
        display: flex;
        flex-direction: column;

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .col {
                width: auto;
            }
        }
    }
}

.save-store-details__container {
    position: relative;

    .save-store-details-close {
        background: transparent;
        border: 0;
        position: absolute;
        left: 95.2%;

        svg {
            display: flex;
        }
    }
}

.save-store__container__link {
    margin-bottom: 25px;

    a {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #2f80ed;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            color: #56ccf2;
        }
    }

    span {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #717579;
        margin-right: 100px;
    }
}

.save-store-btn {
    display: none;
}

.save-store-details__container {
    .row {
        display: flex;
        justify-content: space-between;
        .col {
            &:first-child {
                width: calc(65% - 20px);
            }
            &:last-child {
                width: calc(35% - 20px);
            }
        }
    }
}