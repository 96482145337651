.forgot-credentials {
    margin-top: 21px;
    a:first-of-type {
      margin-right: 30px;
    }
    a {
      transition: all .2s ease-in-out;
      &:hover {
        svg {
          path {
            fill: #FA3535;
          }
        }
      }
    }
  }