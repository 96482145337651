.add-edit-stores__go-live {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    box-sizing: border-box;
    background: #f2f2f2;
    border-radius: 8px;
    height: 295px;
    h3 {
        font-family: "Mulish";
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
    }
    p {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
    }
    hr {
        border: 0;
        background: #e0e0e0;
        height: 1px;
        margin-top: 9px;
        margin-bottom: 22px;
    }
    h4 {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #171b1e;
    }
    h5 {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #4f4f4f;
        margin-top: 25px;
        span {
            color: #fbde57;
        }
    }
    input[type="date"] {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        width: 186px;
        height: 36px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        &::-webkit-calendar-picker-indicator {
            opacity: 1;
            display: block;
            background: url('../../../../assets/icons/MonthIcon.svg') no-repeat;
            width: 18px;
            height: 18px;
        }
    }
}
