.user-info-popup {
    position: absolute;
    top: 100%;
    right: 0;
    margin-right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    z-index: 99;

    &-inner {
        position: relative;
        height: 163px;
        width: 270px;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid #e0e0e0;

        .close-btn {
            position: absolute;
            top: 15px;
            right: 15px;
            border: 0;
            background: transparent;
            cursor: pointer;
            svg {
                width: 20px;
                height: 20px;
                border: 0;
            }
        }
    }
    &__header {
        display: flex;
        align-items: center;
        margin-top: 12px;
        margin-left: 12px;
        img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            border: 2px solid #fa3535;
            margin-right: 6px;
        }
        &__container {
            h5 {
                font-family: "Mulish";
                font-weight: 700;
                font-size: 14px;
                color: #333333;
            }
            span {
                font-family: "Mulish";
                font-weight: 600;
                font-size: 11px;
                color: #333333;
            }
        }
    }
    &__body {
        margin-top: 7px;
        ul {
            li a {
                display: flex;
                align-items: center;
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: 11px;
                padding-right: 10px;
                font-family: "Mulish";
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                cursor: pointer;
                text-decoration: none;
                &.profile-link {
                    background: rgba(224, 224, 224, 0.5);
                    border-left: 6px solid #fa3535;
                    span {
                        font-weight: 700;
                    }
                    svg {
                        border: 0;
                        width: 17.75px;
                        height: 14px;
                        margin-left: auto;
                    }
                }
                &.logout {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 17px;
                }
            }
        }
    }
}
