.settings__email {
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
    border-radius: 20px;
    height: 100%;
    padding: 36px 32px;
    .email-body {
        display: flex;
        align-items: center;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        background-color: #f5f5f5;
        border-radius: 20px;
        height: calc(100% - 80px);
        box-sizing: border-box;
        &__content {
            display: flex;
            align-items: center;
            color: #000;
            background-color: #e7e7e7;
            border-radius: 10px;
            width: 60%;
            flex-direction: column;
            align-items: flex-start;
            padding: 50px;
            box-sizing: border-box;
            @media (max-width: 767.8px) {
                width: 100%;
            }
            @media (max-width: 520px) {
                padding: 30px;
            }
            &--title {
                color: #171b1e;
                font-size: 38px;
                border: 0;
                background: transparent;
                margin-bottom: 30px;
                text-align: center;
                width: 100%;
                @media (max-width: 520px) {
                    font-size: 21px;
                }
                @media (max-width: 390px) {
                    font-size: 15px;
                }
                &::placeholder {
                    color: #171b1e;
                }
            }
            &--code {
                background-color: #fa3535;
                color: #fff;
                border-radius: 16px;
                padding: 8px 16px;
                margin: 0 auto;
                margin-bottom: 20px;
                @media (max-width: 520px) {
                    font-size: 11px;
                }
            }
            &--bottom {
                font-size: 14px;
                border: 0;
                background: transparent;
                display: flex;
                width: 100%;
                color: #000;
                padding: 10px 0 !important;
                text-align: center;
                width: 100%;
                @media (max-width: 520px) {
                    font-size: 11px;
                }
                @media (max-width: 390px) {
                    font-size: 8px;
                }
                &::placeholder {
                    color: #171b1e;
                }
            }
        }
    }
}
