footer {
    flex: 0 1 auto;
    padding: 10px;
    background-color: #d9d9d9;
    @media (max-width: 1440px) {
        padding: 35px;
    }
    @media (max-width: 1024px) {
        padding: 25px;
    }
    @media (max-width: 768px) {
        padding: 20px;
    }
    h5 {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #171b1e;
    }
    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #434446;
    }
    a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #1b1c1e;
        transition: all .2s ease-in-out;
        &:hover {
            color: #676969;
        }
    }
}