.forgot-username {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  
    &__form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 803px;
      width: 100%;
      background: #E7E7E7;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 32px 40px 44px;
      margin: 0 auto;
      position: relative;
  
      h3 {
        font-weight: 400;
        font-size: 26px;
        line-height: 35px;
        color: #171B1E;
        @media screen and (max-width: 500px) {
          margin-top: 50px;
        }
      }
  
      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #171B1E;
        margin-bottom: 21px;
      }
  
      input {
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 10px;
        width: 100%;
        max-width: 280px;
        height: 60px;
        padding: 20px 18px;
        box-sizing: border-box;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
        &::placeholder {
          color: #717579;
        }
      }
  
      & > button {
        width: 177px;
        margin-top: 83px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #717579;
        cursor: pointer;
        transition: all .2s ease-in-out;
        @media screen and (max-width: 500px) {
          margin-top: 27px;
        }
  
        &:hover {
          color: #fff;
          background-color: #FA3535;
        }
      }
    }
  }
  