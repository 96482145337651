.new-password {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    &__form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 803px;
      width: 100%;
      background: #e7e7e7;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 32px 40px 44px;
      margin: 0 auto;
      position: relative;
  
      h3 {
        font-weight: 400;
        font-size: 26px;
        line-height: 35px;
        color: #171b1e;
        margin-bottom: 20px;
        @media screen and (max-width: 500px) {
          margin-top: 50px;
        }
      }
  
      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #171b1e;
        margin-bottom: 21px;
      }
  
      input {
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 10px;
        width: 100%;
        max-width: 280px;
        height: 60px;
        padding: 20px 18px;
        box-sizing: border-box;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
        transition: all .2s ease-in-out;
        &.invalid {
          border-color: #fb6d3b;
          color: #fb6d3b;
          &::placeholder {
            color: #fb6d3b;
          }
        }
        &::placeholder {
          color: #717579;
        }
  
        &:first-of-type {
          margin-bottom: 6px;
        }
      }
  
      & > button {
        width: 177px;
        margin-top: 17px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #cacaca;
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #717579;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
  
        &:hover {
          color: #fff;
          background-color: #faa635;
        }
      }
      .password-view {
        position: relative;
        width: 100%;
        max-width: 280px;
        button {
          height: 20px;
          width: 24px;
          background-image: url("../../../assets/icons/PasswordView/ShowPasswordIcon.svg");
          background-repeat: no-repeat;
          background-position: center;
          border: 0;
          background-color: transparent;
          position: absolute;
          top: 33%;
          right: 15px;
          cursor: pointer;
          outline: none;
          &.active {
            background-image: url("../../../assets/icons/PasswordView/HiddenPasswordIcon.svg");
          }
        }
      }
    }
  }
  