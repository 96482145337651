.zones {
    &__stores {
        display: flex;
        flex-direction: column;
        span {
            &:first-child {
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #000000;
                margin-bottom: 7px;
            }
            &:last-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #717579;
                @media (max-width: 1750px) {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 160px;
                    height: 1.2em;
                    white-space: nowrap;
                }
            }
        }
    }
    &__bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--selectors {
            display: flex;
            & > div {
                margin-right: 20px;
                &:first-child {
                    width: 380px;
                }
            }
        }
        &__settings {
            display: flex;
            align-items: center;
            .btn__upload-files {
                width: 60px;
            }
            button {
                width: 208px;
                height: 60px;
                box-sizing: border-box;
                background: #ffffff;
                border: 1px solid #cacaca;
                border-radius: 10px;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #717579;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    &__status {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .info-popup {
            right: 30%;
            top: -34%;
        }
        &__progress-bar {
            background: #fff;
            border: 1px solid #dcdcdc;
            box-sizing: border-box;
            border-radius: 6px;
            min-width: -webkit-fill-available;
            width: 100%;
            max-width: 162px;
            height: 40px;
            span {
                background: #dfdfdf;
                border-radius: 6px 0 0 6px;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #000;
                height: 100%;
                display: flex;
                align-items: center;
                width: 45%;
                justify-content: center;
            }
        }
        & > button:nth-child(2) {
            display: flex;
            background: transparent;
            border: 0;
            margin-left: 5px;
            cursor: pointer;
            svg {
                pointer-events: none;
            }
        }
    }
    .rdt_TableHead {
        min-height: 65px;
    }
    .rdt_TableHeadRow,
    .rdt_TableRow {
        padding-left: 5px;
        display: flex;
        align-items: flex-start;
        padding-top: 21px;
        padding-bottom: 21px;
        min-height: auto;
    }
    .rdt_TableRow {
        .rdt_TableCell {
            &:last-child {
                & > div {
                    width: 80%;
                    max-width: 250px;
                }
            }
        }
    }
    .custom-sort-icons {
        svg {
            opacity: 1 !important;
        }
    }
    .rdt_Table {
        .rdt_TableBody:nth-child(2) {
            .rdt_TableCell {
                &:nth-child(3) {
                    & > div {
                        width: 100%;
                    }
                }
                &:nth-child(5) {
                    & > div {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.zones-radio {
    .row {
        display: flex;

        &:first-of-type {
            margin-bottom: 8px;
        }
        &:first-of-type {
           & > .col {
            &:first-of-type {
                margin-right: 18px;
            }
           }
        }
        .col {
            width: 100%;
            &:first-of-type {
                margin-right: 12px;
            }

            label {
                font-weight: 600;
                font-size: 10px;
                line-height: 14px;
                color: #717579;
                margin-left: 3px;
            }

            [type="checkbox"]:checked,
            [type="checkbox"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }

            [type="checkbox"]:checked + label,
            [type="checkbox"]:not(:checked) + label {
                position: relative;
                padding-left: 26px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: #666;
            }

            [type="checkbox"]:checked + label:before,
            [type="checkbox"]:not(:checked) + label:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 18px;
                height: 18px;
                background: #fff;
                border: 1px solid #dadada;
                border-radius: 100%;
            }

            [type="checkbox"]:checked + label:after,
            [type="checkbox"]:not(:checked) + label:after {
                content: "";
                width: 18px;
                height: 18px;
                background: #fa3535;
                position: absolute;
                top: 1px;
                left: 1px;
                transition: all 0.2s ease;
                border-radius: 100%;
            }

            [type="checkbox"]:not(:checked) + label:after {
                opacity: 0;
                transform: scale(0);
            }

            [type="checkbox"]:checked + label:after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

.zones__selectors {
    & > span {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
    }
}
