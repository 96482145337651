.add-edit-stores__store-owners {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    box-sizing: border-box;
    background: #f2f2f2;
    border-radius: 8px;
    height: 195px;
    margin-bottom: 25px;
    h3 {
        font-family: "Mulish";
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
    }
    p {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
    }
    hr {
        border: 0;
        background: #e0e0e0;
        height: 1px;
        margin-top: 9px;
        margin-bottom: 22px;
    }
    h4 {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #171b1e;
    }
}
