.lsm-popup__sidebar__tag
{
    flex: 1 1 100%;
}

.react-tags-wrapper {
    background: #ffffff;
    border: 1px solid #b3b3b3;
    border-radius: 4px;
    height: 80px;
    padding: 10px;
    position: relative;
}

.ReactTags__tagInputField {
    font-family: "Mulish";
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #bdbdbd;
    border: 0 !important;
    outline: none;
}

.ReactTags__selected {
//     display: flex;
//     flex-wrap: wrap;
//     flex-direction: row;
}

.ReactTags__tag {
    color: #fff;
    background: #f40027;
    padding: 0 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 5px;
    margin-bottom: 5px;
    font-family: "Mulish",sans-serif;
    font-size: 11px;
    button {
        border: 0;
        background: transparent;
        color: #fff;
        margin-left: 5px;
    }
}

.tag-wrapper {
    height: 20px;
    float: left;
}
.ReactTags__clearAll {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #2F80ED;
    cursor: pointer;
    border: 0;
    background: transparent;
}