.settings__general {
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
    border-radius: 20px;
    height: 100%;
    padding: 36px 32px;
    h2 {
        font-family: "Mulish";
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;
        color: #333333;
    }
    p {
        font-family: "Mulish";
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        margin-top: 4px;
    }
    &--selector {
        margin-top: 23px;
        h4 {
            font-family: "Mulish";
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
        }
        p {
            font-family: "Mulish";
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #4f4f4f;
            margin-bottom: 3px;
            a {
                color: #4a4aff;
            }
        }
    }
    button {
        margin-top: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fade57;
        border-radius: 4px;
        width: 116px;
        height: 36px;
        font: 700 11px/1 "Mulish", sans-serif;
        text-transform: uppercase;
        color: #4f4f4f;
        border: 0;
        cursor: pointer;
        transition: filter 0.3s ease-in-out;
        &:hover {
            filter: brightness(1.1);
        }
    }
}
