.add-edit-stores {
    background-color: #fff;
    padding: 35px;
    @media only screen and (max-width: 767.8px) {
        padding: 20px;
    }
    &__locate-store {
        background: #f2f2f2;
        border-radius: 8px;
        padding: 20px 27px;
        box-sizing: border-box;
        width: calc(66.66% - 10px);
        height: 195px;
        @media only screen and (max-width: 1390px) {
            width: 100%;
        }
        h5 {
            font-family: "Mulish";
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
        }
        p {
            font-family: "Mulish";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #333333;
        }
        hr {
            border: 0;
            background: #e0e0e0;
            height: 1px;
            margin-top: 9px;
            margin-bottom: 22px;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        margin-top: 21px;
        flex-wrap: wrap;
    }
    &__col {
        display: flex;
        flex-direction: column;
        width: calc(33.33% - 20px);
        @media only screen and (max-width: 1390px) {
            &:first-child {
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 20px;
                width: 100%;
                & > div {
                    width: calc(50% - 10px);
                }
            }
            &:nth-child(2) {
                width: calc(50% - 10px);
                position: relative;
                top: -100px;
            }
            &:nth-child(3) {
                width: calc(50% - 10px);
            }
        }
        @media only screen and (max-width: 767px) {
            &:first-child {
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 20px;
                width: 100%;
                & > div {
                    width: 100%;
                }
            }
            &:nth-child(2) {
                width: 100%;
                position: relative;
                top: 0;
            }
            &:nth-child(3) {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}
