main {
    width: 100%;
    padding: 0 50px;
    overflow: hidden;
    @media (max-width: 1440px) {
        padding: 0 35px;
    }
    @media (max-width: 1024px) {
        padding: 0 25px;
    }
    @media (max-width: 768px) {
        padding: 0 20px;
    }
}
