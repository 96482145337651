.profile {
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 20px;
  padding: 30px;

  .row {
    display: flex;
    @media (max-width: 767.98px) {
      flex-direction: column;
    }

    .col {
      width: 100%;
      max-width: 379px;
      @media (max-width: 767.98px) {
        max-width: 100%;
      }
      &:first-child {
        margin-right: 56px;
      }
      & > h5 {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #333333;
      }

      & > p {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
      }

      hr {
        border: 0;
        height: 1px;
        background-color: #e0e0e0;
      }

      .profile-details {
        display: flex;
        flex-direction: column;
        &__placeholder, &__placeholder--value {
          font-family: "Mulish";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #bdbdbd;
        }
        &__placeholder--value {
          color: #333333;
        }
        &__check-username {
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          height: 36px;
          box-sizing: border-box;
          padding: 2px;
          display: flex;
          align-items: center;
          input {
            height: 100%;
            border: 0;
            outline: none;
            padding-left: 10px;
            font-family: "Mulish";
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #333333;
            width: 100%;
            &::placeholder {
              color: #bdbdbd;
            }
          }
          .btn-save {
            min-width: 100px;
            width: 100px;
            height: 32px;
            border-radius: 3px;
            color: #fff;
            background: #fa3535;
            border-color: #fa3535;
            &:hover {
                background-color: #f00d0d;
                border: 1px solid #f00d0d;
            }
          }
        }
        &__footer {
          margin-top: 37px;
          padding-top: 17px;
          border-top: 1px solid #e0e0e0;
          &__delete-account {
            display: flex;
            justify-content: end;
          }
          & > p {
            font-family: "Mulish";
            font-weight: 400;
            font-size: 10px;
            line-height: 13px;
            color: #828282;
          }
        }
        label {
          font-family: "Mulish";
          font-weight: 400;
          font-size: 12px;
          color: #4f4f4f;
          span {
            color: #fa3535;
          }
        }
        & > input {
          height: 36px;
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #333333;
          outline: none;
          &::placeholder {
            color: #bdbdbd;
          }
        }
      }
    }
  }
}

.user-avatar {
  display: flex;
  width: fit-content;
  &__upload {
    width: 122px;
    position: relative;
    img {
      width: 118px;
      height: 118px;
      border-radius: 100%;
      border: 2px solid #fa3535;
    }
    &__container {
      position: absolute;
      bottom: 5px;
      width: 100%;
      display: flex;
      justify-content: center;
      label {
        background: #fa3535;
        border: none;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        width: 90px;
        height: 25px;
        position: relative;
        transition: all 0.3s ease-in-out;
        font-family: "Mulish";
        font-weight: 700;
        font-size: 11px;
        text-transform: uppercase;
        &:hover {
          color: #fff;
          background-color: #f00d0d;
        }
      }
      input {
        height: 0;
        overflow: hidden;
        width: 0;
        display: none;
      }
    }
  }
  &__info {
    margin-left: 10px;
    h3 {
      font-family: "Mulish";
      font-weight: 700;
      font-size: 25px;
      color: #333333;
    }
    h4 {
      font-family: "Mulish";
      font-weight: 700;
      font-size: 16px;
      color: #333333;
    }
    p {
      font-family: "Mulish";
      font-weight: 400;
      font-size: 14px;
      color: #828282;
      margin-top: 4px;
    }
  }
}

.user-cover {
  position: relative;
  display: flex;
  width: fit-content;
  width: 100%;
  &__preview {
    width: 100%;
  }
  img {
    width: 100%;
    border-radius: 8px;
    border: 2px solid #fa3535;
    height: 96px;
    object-fit: contain;
  }
  &__upload {
    width: 379px;
    position: relative;
    &__container {
      position: absolute;
      bottom: -11px;
      width: 100%;
      display: flex;
      justify-content: center;
      label {
        background: #fa3535;
        border: none;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        width: 90px;
        height: 25px;
        position: relative;
        transition: all 0.3s ease-in-out;
        font-family: "Mulish";
        font-weight: 700;
        font-size: 11px;
        text-transform: uppercase;
        &:hover {
          color: #fff;
          background: #f00d0d;
        }
      }
      input {
        height: 0;
        overflow: hidden;
        width: 0;
        display: none;
      }
    }
  }
}
