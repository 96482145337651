.widget__promtion {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #ffffff;
    border-radius: 20px;
    height: 478px;
    @media (min-width: 320px) and (max-width: 767.8px) {
        height: 350px;
        margin-top: 20px;
    }
    &__header {
        width: auto !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
        padding-left: 24px;
        padding-right: 20px;
        margin-bottom: 10px;
        @media (min-width: 320px) and (max-width: 475px) {
            flex-wrap: wrap;
            .widget__promtion__header__switch {
                margin-top: 10px;
            }
        }
        div {
            width: 100% !important;
            h6 {
                font-weight: 800;
                font-size: 28px;
                color: #717579;
                @media (min-width: 1440px) and (max-width: 1600px) {
                    font-size: 21px;
                }
            }
            span {
                font-weight: 600;
                font-size: 16px;
                color: #717579;
                @media (min-width: 1440px) and (max-width: 1600px) {
                    font-size: 14px;
                }
            }
        }
        &__switch {
            display: flex;
            div:first-child {
                margin-right: 20px;
                @media (min-width: 1600px) {
                    margin-right: 40px;
                }
            }
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: #666;
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 24px;
                height: 24px;
                background-image: url('../../../assets/icons/radioButons.svg') !important;
                border-radius: 100%;
                background: #fff;
            }
            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
                content: "";
                width: 24px;
                height: 24px;
                background-image: url('../../../assets/icons/radioButtonsChecked.svg') !important;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 100%;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }
            [type="radio"]:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }
        .widget__promtion--btn-details {
            background: transparent;
            margin-left: 26px;
            border: 0;
            cursor: pointer;
            svg {
                pointer-events: none;
                path {
                    transition: all .2s ease-in-out;
                }
            }
            &:hover {
                svg {
                    path {
                        fill: #F40027;
                    }
                }
            }
        }
    }
    &__content {
        height: 100%;
        .recharts-surface {
            margin-left: 50px;
            @media (max-width: 1700px) {
                margin-left: 0;
            }
        }
    }
}
