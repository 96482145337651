.dashboard {
    width: 100%;
    &__bar {
        display: flex;
        justify-content: space-between;
        @media (max-width: 600px) {
            flex-direction: column-reverse;
        }
    }
    &__content {
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @media (min-width: 1024px) and (max-width: 1439.8px) {
            flex-direction: row;
            justify-content: space-between;
        }
        & > .col {
            @media (min-width: 320px) and (max-width: 767.8px) {
                &:first-child {
                    display: flex;
                    flex-direction: column;
                    div {
                        width: 100%;
                        &:last-child {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            @media (min-width: 768px) and (max-width: 1023.8px) {
                &:first-child {
                    display: flex;
                    justify-content: space-between;
                    div {
                        width: calc(50% - 10px);
                    }
                }
            }
            @media (min-width: 1024px) and (max-width: 1439.8px) {
                &:first-child {
                    display: flex;
                    flex-direction: column;
                    width: calc(50% - 20px);
                    
                }
                &:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    width: calc(50% - 20px);
                }
                &:nth-child(3) {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
            }
        }
        @media screen and (min-width: 1440px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1.5fr;
            grid-template-rows: repeat(1, minmax(40px, auto));
            grid-gap: 20px;
        }
    }
}

.dashboard__bar {
    .btn__compaing {
        min-width: 208px;
    }
}