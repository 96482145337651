.widget__files {
    display: flex;
    flex-direction: column;
    height: 230px;
    background: #ffffff;
    border-radius: 20px;
    margin-bottom: 20px;
    &__header {
        width: auto !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
        padding-left: 24px;
        padding-right: 20px;
        margin-bottom: 10px;
        div {
            width: 100% !important;
            h6 {
                font-weight: 800;
                font-size: 28px;
                color: #717579;
                @media (min-width: 1440px) and (max-width: 1600px) {
                    font-size: 21px;
                }
            }
            span {
                font-weight: 600;
                font-size: 16px;
                color: #717579;
                @media (min-width: 1440px) and (max-width: 1600px) {
                    font-size: 14px;
                }
            }
        }
    }
    &__body {
        height: 100%;
        width: auto !important;
        @media (min-width: 768px) and (max-width: 899.8px) {
            height: 112px;
        }
        @media (min-width: 320px) and (max-width: 767.8px) {
            height: 90px;
        }
    }
    &__footer {
        width: auto !important;
        z-index: 1;
        padding-left: 23px;
        padding-bottom: 17px;
        padding-right: 17px;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
    }

    @media (min-width: 320px) and (max-width: 767.8px) {
        height: 220px;
    }

    @media (min-width: 768px) and (max-width: 899.8px) {
        height: 250px;
    }

    @media only screen and (min-width: 1150px) and (max-width: 1499.8px) {
        height: 229px;
    }
    @media only screen and (min-width: 1500px) {
        height: 229px;
    }
}
