.request-login-banner {
  width: 280px;
  height: 64px;
  background: #dadada;
  padding-top: 10px;
  padding-left: 18px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  @media screen and (max-width: 550px) {
    width: 200px;
  }
  h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #171b1e;
  }
  svg {
    transition: all 0.2s ease-in-out;
    path {
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      path {
        fill: #fa3535;
      }
    }
  }
}
