.lsm_coupon_editor {
    width: 100%;
    &.hidden {
        display: none;
    }
}

.lsm-popup__content__description {
    display: flex;
    justify-content: space-between;
    & > div:last-child {
        width: 146px;
    }
}

.lsm-popup__content {
    .lsm__content__header__coupon--dnd,
    .lsm__content__header__coupon--pdf {
        img {
            padding: 0;
            background-color: transparent;
        }
    }
    .lsm__content__header__coupon--dnd {
        position: relative;

        .droppableArea {
            position: absolute;
            &.first
            {
                top: 58px;
                left: 277px;
            }
            &.second
            {
                top: 58px;
                left: 496px;
            }
            &.third
            {
                top: 275px;
                left: 277px;
            }
            &.fourth
            {
                top: 275px;
                left: 496px;
            }
        }
    }
}

.lsm__content__header__coupon--dnd,
.lsm__content__header__coupon--pdf {
    display: flex;
    &.disable {
        display: none;
    }
}

.lsm-coupons {
    &__inputs {
        width: 100%;
        flex-basis: 100%;
        & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 10px;
            &:first-of-type {
                margin-top: 2px;
            }
            label {
                font-family: "Mulish";
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #4f4f4f;
                margin-bottom: 3px;
            }
            & > div {
                width: 100%;
            }
            input[type="text"],input[type="date"] {
                font-family: "Mulish";
                font-weight: 400;
                font-size: 14px;
                color: #333;
                border: 1px solid #b3b3b3;
                border-radius: 4px;
                padding: 10px;
                width: 100%;
                height: 36px;
                box-sizing: border-box;
                outline-color: #fa3535;
            }
        }
    }
}

.lsm-popup__sidebar__btn-group--coupon {
    margin-top: 20px;
    & > div {
        button {
            &:first-child {
                width: calc(63% - 11.5px);
                &.full {
                    width: 100%;
                }
            }
            &.btn__email {
                width: calc(37% - 11.5px);
                &.full {
                    width: 100%;
                }
            }
        }
    }
}

.lsm-popup__sidebar {
    // margin-top: 42px;
    // width: 40%;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: nowrap;
    // align-items: flex-start;
    // align-content: flex-start;
    // justify-content: space-evenly;
    // gap: 10px;
    // h5 {
    //     flex-grow: 1;
    // }
    // > * {
    //     flex-grow: 1;
    // }
    // & > * {
    //     flex: 1 1 0px;
    // }
    &.lsm-popup__sidebar--front-view {
        &.disable {
            display: none;
        }
    }
    &.lsm-popup__sidebar--back-view {
        &.disable {
            display: none;
        }
    }
    & > button {
        flex: 1 1 0px;
        border: 1px solid #a4a4a4;
        border-radius: 4px;
        background: transparent;
        font-family: "Mulish";
        font-weight: 700;
        font-size: 11px;
        color: #4f4f4f;
        height: 36px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            color: #fff;
            background: #fa3535;
            border-color: #fa3535;
        }
    }
    .btn__save {
        color: #fff;
        background: #fa3535;
        border-color: #fa3535;
        &:hover {
            background-color: #f00d0d;
            border: 1px solid #f00d0d;
        }
    }
}

$primary: #ff0000;
$secondary: #ff0000;

.availableCoupons {
    flex-basis: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 550px;
}

.draggableThing {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 131px;
    width: 131px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;

    &:hover {
        border: 1px solid $primary;
    }

    &::before {
        content: attr(id);
    }
}

.droppableArea {
    background: transparent;
    position: absolute;
    top: 57px;
    right: 290px;

    width: 208px;
    height: 208px;
    span {
        position: absolute;
        bottom: 15px;
        color: #fff;
        font-size: 6px;
        left: 6px;
        width: 178px;
    }

    img {
        object-fit: cover;
    }

    /*
    &.second {
        top: 279px;
    }

    &.third {
        right: 67px;
    }

    &.fourth {
        top: 279px;
        right: 67px;
    }
    */

    p {
        font-size: 15px;
        justify-self: stretch;
        flex-basis: 100%;
        text-align: center;
        pointer-events: none;
        text-transform: uppercase;
        font-family: sans-serif;
        color: $primary;
    }

    &.activeDropArea {
        border-color: $secondary;
    }

    .draggableThing {
        padding: 0;
        background: transparent;
    }
}

.coupon-1,
.coupon-2,
.coupon-3,
.coupon-4,
.coupon-5,
.coupon-6,
.coupon-7,
.coupon-8,
.coupon-9,
.coupon-10,
.coupon-11 {
    display: none;
}

.coupon-1:first-child,
.coupon-1 + .coupon-2,
.coupon-1 + .coupon-2 {
    display: flex;
}

.coupon-3:first-child,
.coupon-2 + .coupon-3,
.coupon-2 + .coupon-3 {
    display: flex;
}

.coupon-3:first-child,
.coupon-2 + .coupon-3,
.coupon-2 + .coupon-3 {
    display: flex;
}

.coupon-3:first-child,
.coupon-2 + .coupon-3,
.coupon-2 + .coupon-3 {
    display: flex;
}

.coupon-4:first-child,
.coupon-3 + .coupon-4,
.coupon-3 + .coupon-4 {
    display: flex;
}

.coupon-5:first-child,
.coupon-4 + .coupon-5,
.coupon-4 + .coupon-5 {
    display: flex;
}

.coupon-6:first-child,
.coupon-5 + .coupon-6,
.coupon-5 + .coupon-6 {
    display: flex;
}

.coupon-7:first-child,
.coupon-6 + .coupon-7,
.coupon-6 + .coupon-7 {
    display: flex;
}

.coupon-8:first-child,
.coupon-7 + .coupon-8,
.coupon-7 + .coupon-8 {
    display: flex;
}

.coupon-9:first-child,
.coupon-8 + .coupon-9,
.coupon-8 + .coupon-9 {
    display: flex;
}

.coupon-10:first-child,
.coupon-9 + .coupon-10,
.coupon-9 + .coupon-10 {
    display: flex;
}

.coupon-11:first-child,
.coupon-10 + .coupon-11,
.coupon-10 + .coupon-11 {
    display: flex;
}

.droppableArea img {
    display: flex;
}

.popup--coupons {
    zoom: 0.92;
}

.lsm__content__header__coupon--dnd__input__store {
    background: transparent;
    width: 65%;
    font-family: "National2";
    font-size: 10px;
    border: 0;
    position: absolute;
    bottom: 82px;
    left: 52px;
    color: #fff;
    &::placeholder {
        color: #fff;
    }
}

.coupon-store-name {
    background: transparent;
    position: absolute;
    text-align: center;
    font-size: 35px;
    bottom: 162px;
    left: 355px;
    font-family: "National2";
    font-weight: 700;
    border: 0;
    color: #fff;
    outline: none;
    width: 450px;
    &::placeholder {
        color: #fff;
    }
}

.coupon-store-address-1 {
    width: 29%;
    position: absolute;
    bottom: 59px;
    left: 430px;
    font-family: "National2";
    font-size: 22px;
    background: transparent;
    border: 0;
    color: #fff;
    outline: none;
    transform: scale(0.8,1);
    &::placeholder {
        color: #fff;
    }
}

.coupon-store-address-2 {
    width: 29%;
    position: absolute;
    bottom: 35px;
    left: 430px;
    font-family: "National2";
    font-size: 22px;
    background: transparent;
    border: 0;
    color: #fff;
    outline: none;
    transform: scale(0.8,1);
    &::placeholder {
        color: #fff;
    }
}

.popup--email-content {
    display: flex;
    align-items: center;
    max-height: 300px !important;
}

.popup--email-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1 !important;
}