.forgot-password {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  
    &__form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 803px;
      width: 100%;
      background: #e7e7e7;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 32px 40px 44px;
      margin: 0 auto;
      position: relative;
      &__row {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        @media screen and (max-width: 700px) {
          flex-direction: column;
          align-items: flex-start;
        }
        span {
          margin: 0 21px;
          font-weight: 400;
          font-size: 26px;
          line-height: 35px;
          color: #171b1e;
          @media screen and (max-width: 700px) {
            margin: 10px 0;
            width: 100%;
            max-width: 260px;
            text-align: center;
            box-sizing: border-box;
          }
        }
      }
  
      h3 {
        font-weight: 400;
        font-size: 26px;
        line-height: 35px;
        color: #171b1e;
        @media screen and (max-width: 500px) {
          margin-top: 50px;
        }
      }
  
      & > span {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #171b1e;
        margin-bottom: 21px;
      }
  
      input {
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 10px;
        width: 100%;
        max-width: 280px;
        height: 60px;
        padding: 20px 18px;
        box-sizing: border-box;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
        &::placeholder {
          color: #717579;
        }
      }
  
      & > button {
        width: 177px;
        margin-top: 83px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #cacaca;
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #717579;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 500px) {
          margin-top: 27px;
        }
  
        &:hover {
          color: #fff;
          background-color: #FA3535;
        }
      }
    }
  }
  