.header__user-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    min-width: 105px;
    position: relative;
    @media screen and (max-width: 900px) {
        min-width: auto;
    }
    &__container {
        margin-right: 18px;
        text-align: right;
        @media screen and (max-width: 900px) {
            display: none;
        }
        h5 {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: #171b1e;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #717579;
        }
    }
    img {
        width: 57px;
        height: 57px;
        border: 1px solid #D0D0D0;
        border-radius: 6px;
    }
}


.btn-user-info-popup {
    img  {
        pointer-events: none;

    }
}