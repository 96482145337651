.lsm {
    overflow: hidden;
    .lsm-dashboard-slider {
        height: 421px;
        @media screen and (max-width: 900px) {
            height: 375px;
        }
        @media screen and (max-width: 767px) {
            height: 250px;
        }
        .swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .lsm-dashboard-cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .lsm-dashboard-card {
            margin-top: 18px;
            width: calc(33.33% - 9px);
            @media screen and (max-width: 767px) {
                width: calc(50% - 9px);
                height: 250px;
            }
            @media screen and (max-width: 600px) {
                height: 150px;
            }
            @media screen and (max-width: 500px) {
                width: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background: #707579;
    @media screen and (max-width: 425px) {
        width: 14px;
        height: 14px;
    }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    text-align: right;
    left: -40px !important;
    bottom: 20px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: #707579;
    font-size: 32px !important;
    @media screen and (max-width: 425px) {
        font-size: 21px !important;
    }
}

.settings-btn {
    background: #fff;
    border-radius: 10px;
    margin-left: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    height: 50px;
    border: 1px solid #cacaca;
    margin-left: auto;
    margin-bottom: 15px;
    svg {
        path {
            transition: all 0.2s ease-in-out;
        }
    }
    &:hover {
        background: #f40027;
        svg {
            path {
                fill: #fff;
            }
        }
    }
}

.lsm-popup-settings {
    h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 35px;
        text-align: center;
        color: #717579;
        margin: 0;
        text-align: center;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #171b1e;
        margin-bottom: 20px;
    }
    hr {
        margin: 10px 0;
        border: 0;
        height: 1px;
        background-color: #e0e0e0;
    }
    &__slider {
        h4 {
            font-family: "Nunito Sans";
            font-weight: 600;
            font-size: 18px;
            color: #171b1e;
            margin-bottom: 20px;
        }
        ul {
            li {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                & > button {
                    background: transparent;
                    width: 24px;
                    height: 24px;
                    border: 0;
                    cursor: pointer;
                    &:first-child {
                        margin-left: auto;
                        display: flex;
                    
                    }
                    &:last-of-type {
                        margin-right: 0;
                        margin-left: 5px;
                    }
                    svg {
                        path {
                            transition: all .2s ease-in-out;
                        }
                    }
                    &:hover {
                        svg {
                            path {
                                fill: #f40027;
                            }
                        }
                    }
                }
            }
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 20px;

        label {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 35px;
            color: #171b1e;
        }
        input {
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            width: 100%;
            font-family: "Mulish";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #333;
            padding: 15px;
        }
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-top: 10px;
        width: 140px;
        height: 37px;
        background: #f40027;
        border-radius: 6px;
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        color: #ffffff;
        border: 0;
        transition: all .2s ease-in-out;
        cursor: pointer;
        &:hover {
            filter: contrast(1.2);
        }
    }
}

.lsm-popup-settings__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    & > div {
        width: 100%;
        border: 1px solid #eee;
        border-radius: 10px;
    }
}

.lsm-popup-settings__slider__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    border-bottom: 1px solid #eee;
    h4 {
        margin-bottom: 0 !important;
    }
    button {
        margin-top: 0 !important;
    }
}

.lsm-popup-settings__slider__body {
    padding: 20px;
    padding-top: 0;
}

.lsm-card-dashboard {
    width: 100%;
    height: 100%;
    img {
        object-fit: cover;
    }
}

.lsm-popup-settings__slider__body__slider {
    height: 200px;
    & > div {
        height: 200px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.lsm-popup-settings__slider__body__cards {
    .lsm-grid {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        & > div {
            width: calc(33.33% - 17px);
            img {
                object-fit: cover;
                width: 100%;
            }
        }
    }
}

.lsm-popup-settings__slider__body__cards .lsm-card>div {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;

}

.lsm-popup-settings__slider__body__cards .lsm-card>div button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: 10px;
    width: 140px;
    height: 37px;
    background: #f40027;
    border-radius: 6px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    border: 0;
    transition: all .2s ease-in-out;
    cursor: pointer;
    background: #fff;
    width: 40px;
    &:hover {
        background-color: #f40027;
        svg {
            path {
                fill: #fff;
            }
        }
    }
}

.lsm-popup-settings__slider__body__cards .lsm-card>div button:first-child {
    margin-right: 10px;
}

.lsm-popup-settings__slider__body__slider__content .btn-group {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    align-items: center;
}

.lsm-popup-settings__slider__body__slider__content .btn-group button:first-child {
    margin-right: 10px
}

.lsm-popup-settings__slider__body__slider__content .btn-group button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: 10px;
    width: 140px;
    height: 37px;
    background: #f40027;
    border-radius: 6px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    border: 0;
    transition: all .2s ease-in-out;
    cursor: pointer;
    background: #fff;
    width: 40px;
    &:hover {
        background-color: #f40027;
        svg {
            path {
                fill: #fff;
            }
        }
    }
}

.edit-lsm-dashboard-card input {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 10px;
    height: 60px;
    box-sizing: border-box;
    padding: 10px 10px 10px 15px;
    outline: none;
    font-size: 16px;
    color: #171b1e;
    margin-top: 20px;
}

.romove-lsm-dashboard-card h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #171b1e;
    text-align: center;
}

.romove-lsm-dashboard-card .btn-group {
    display: flex;
    justify-content: center;
    margin-top: 20px
}

.romove-lsm-dashboard-card .btn-group button:last-child {
    background: #56ccf2;
    border: 1px solid #56ccf2;
    box-sizing: border-box;
    border-radius: 4px;
    width: 108px;
    height: 34px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #fff;
    margin-right: 7px;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.romove-lsm-dashboard-card p {
    font-size: 15px;
    line-height: 20px;
    color: #717579;
    text-align: center;
    margin-bottom: 10px;
}

.romove-lsm-dashboard-card .btn-group button:first-child {
    background: transparent;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 4px;
    width: 108px;
    height: 34px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #2f80ed;
    margin-right: 7px;
    transition: all .3s ease-in-out;
    cursor: pointer;
}