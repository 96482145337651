.lsm-card {
    max-width: 450px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: relative;
    
    &:hover {
        .lsm-card__shadow {
            border-color: #cacaca;
        }
    }
    &__shadow {
        width: 100%;
        height: 100%;
        border: 2px solid transparent;
        border-radius: 10px;
        position: absolute;
        transition: all .2s ease-in-out;
    }
    &__header {
        padding: 50px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        .react-pdf__Document {
            .react-pdf__Page__canvas {
                max-width: 100%;
                max-height: calc(300px - 50px) !important;
                object-fit: contain;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        audio {
            width: 100%;
            pointer-events: none;
        }
    }
    &__footer {
        background: #f3f1ec;
        height: 83px;
        h3 {
            font-weight: 600;
            font-size: 16px;
            color: #000000;
            padding: 20px;
        }
    }
}
