.no-match {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #e7e7e7;
    border-radius: 10px;
    margin: 40px 40px 0;
    @media (max-width: 1440px) {
        margin: 35px 35px 0;
    }
    @media (max-width: 1024px) {
        margin: 0 25px;
        margin: 25px 25px 0;
    }
    @media (max-width: 768px) {
        margin: 0 20px;
        margin: 25px 25px 0;
    }
    h1 {
        font-weight: 400;
        font-size: 96px;
        color: #171b1e;
        @media screen and (max-width: 500px) {
            font-size: 72px;
        }
    }
    p {
        font-weight: 400;
        font-size: 24px;
        color: #171b1e;
        @media screen and (max-width: 500px) {
            font-size: 18px;
        }
    }
}