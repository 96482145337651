.settings__request-login {
    .btn-group {
        margin-bottom: 17px;
        button {
            width: 142px;
            height: 50px;
            cursor: pointer;
            background: #fff;
            border: 1px solid #CACACA;
            border-radius: 10px;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #333333;
            transition: all .2s ease-in-out;
            @media (max-width: 450px) {
                width: 120px;
                height: 45px;
            }
            &:hover {
                color: #fff;
                background: #FA3535;
            }
            &:first-child {
                margin-right: 24px;
                @media (max-width: 450px) {
                    margin-right: 20px;
                }
            }
        }
    }
}