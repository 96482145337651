header {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    padding: 20px 50px 0;
    @media (max-width: 1440px) {
        padding: 35px 35px 0;
    }
    @media (max-width: 1024px) {
        padding: 25px 25px 0;
    }
    @media (max-width: 768px) {
        padding: 20px 20px 0;
    }
}
