.widget__promos {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #ffffff;
    border-radius: 20px;
    margin-bottom: 20px;
    height: 478px;
    overflow: hidden;
    @media (min-width: 320px) and (max-width: 767.8px) {
        height: 350px;
    }
    &__header {
        width: auto !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
        padding-left: 24px;
        padding-right: 20px;
        margin-bottom: 10px;
        div {
            width: 100% !important;
            h6 {
                font-weight: 800;
                font-size: 28px;
                color: #717579;
                @media (min-width: 1440px) and (max-width: 1600px) {
                    font-size: 21px;
                }
            }
            span {
                font-weight: 600;
                font-size: 16px;
                color: #717579;
                @media (min-width: 1440px) and (max-width: 1600px) {
                    font-size: 14px;
                }
            }
        }
    }
    &__body {
        height: 100%;
        padding-left: 24px;
        border-top: 1px solid #eeeeee;
        overflow-y: scroll;

        /* total width */
        &::-webkit-scrollbar {
            background-color: #fff;
            width: 35px;
        }

        /* background of the scrollbar except button or resizer */
        &::-webkit-scrollbar-track {
            background-color: #fff;
        }

        /* scrollbar itself */
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            background-image: url("../../../assets/icons/scroll-bar.svg");
            background-repeat: no-repeat;
            border-radius: 16px;
            border: 4px solid #fff;
            background-position: center 10px;
        }

        /* set button(top and bottom of the scrollbar) */
        &::-webkit-scrollbar-button {
            display: none;
        }
        & > ul {
            & > li {
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                text-decoration-line: underline;
                color: #717579;
                padding-bottom: 9px;
                border-right: 1px solid #eeeeee;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:first-child {
                    padding-top: 16px;
                }
                &:hover {
                    color: #F40027;
                }
            }
        }
    }
    &__popup {
        @media (max-width: 550px) {
           overflow-y: scroll;
           height: 500px;
        }
        hr {
            position: absolute;
            width: 100%;
            left: 0;
            border: 0;
            height: 1px;
            background-color: #eeeeee;
        }
        &__header {
            margin-bottom: 10px;
            h3 {
                font-weight: 800;
                font-size: 28px;
                line-height: 38px;
                color: #717579;
            }
            span {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #717579;
            }
        }
        &__content {
            margin-top: 63px;
            .row {
                display: flex;
                flex-wrap: wrap;
                .col {
                    width: 25%;
                    text-align: center;
                    @media (max-width: 950px) {
                        width: 50%;
                        &:first-child, &:nth-child(2) {
                            margin-bottom: 20px;
                        }
                    }
                    @media (max-width: 550px) {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    &:nth-child(3) {
                        border-right: 3px solid #eeeeee;
                    }

                    h5 {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        color: #717579;
                    }
                    span {
                        font-weight: 800;
                        font-size: 24px;
                        line-height: 33px;
                        color: #717579;
                    }
                    .widget__promos__popup__content--widget {
                        height: 200px;
                        margin-top: 10px;
                    }
                    .widget__promos__popup__content--widget--live {
                        ul {
                            text-align: left;
                            padding-left: 19%;
                            margin-top: 10px;
                            li {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 30px;
                                color: #717579;
                            }
                        }
                    }
                }
            }
        }
        &__footer {
            display: flex;
            justify-content: end;
            width: 100%;
            margin-top: 45px;
            .download__pdf {
                width: 211px;
                height: 50px;
                background: #ffffff;
                border: 1px solid #cacaca;
                border-radius: 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                padding-left: 12px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #717579;
                cursor: pointer;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #FA3535;
                    color: #ffffff;
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
                svg {
                    path {
                        transition: all .2s ease-in-out;
                    }
                    &:first-child {
                        margin-right: 8px;
                    }
                    &:last-child {
                        margin-left: auto;
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}
