.settings-pods {
    &__btn {
      background: #fff;
      border-radius: 10px;
      margin-left: 10px;
      padding-left: 15px;
      height: 60px;
      padding-right: 15px;
      border: 1px solid #cacaca;
      display: flex;
      align-items: center;
      transition: all .3s ease-in-out;
      cursor: pointer;
  
      svg {
        width: 20px;
        height: 20px;
        transition: all .3s ease-in-out;
  
        path {
          transition: all .3s ease-in-out;
        }
      }
  
      &:hover {
        background: #FA3535;
  
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  
    &__popup {
      &-content {
        width: 50%;
        height: 90%;
        padding: 50px;
      }
  
      &-overlay {
        opacity: 1 !important;
        background: rgba(0, 0, 0, .3);
      }
  
      &__content {
        width: 80%;
        margin: 0 auto;
        & > h1 {
          font-weight: 700;
          font-size: 26px;
          line-height: 35px;
          text-align: center;
          color: #717579;
          margin: 0;
        }
        & > p {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          text-align: center;
          color: #171B1E;
          margin-bottom: 20px;
        }
        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          &__search-bar {
            width: 45%;
  
            input {
              border: 1px solid #EEEEEE;
              box-sizing: border-box;
              border-radius: 4px;
              padding: 10px;
              width: 100%;
              outline: none;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
  
              &::placeholder {
                color: #D7D7D7;
              }
            }
          }
  
          &__add {
            a {
              background: #F2994A;
              padding: 10px;
              color: #fff;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              min-width: 150px;
              justify-content: center;
              display: flex;
              border-radius: 4px;
              cursor: pointer;
              transition: all .3s ease-in-out;
              &:hover {
                filter: contrast(1.2);
              }
            }
          }
        }
        &__container {
          border: 1px solid #eee;
          border-radius: 10px;
          margin-bottom: 10px;
        }
        &__body {
          &__list {
            padding: 0;
            margin: 0;
            list-style-type: none;
            max-height: 54vh;
            overflow: auto;
            border-top: 1px solid #EEEEEE;
            &::-webkit-scrollbar {
              background-color: #fff;
              width: 16px;
            }
  
            &::-webkit-scrollbar-track {
              background-color: #fff;
            }
  
            &::-webkit-scrollbar-thumb {
              background-color: #babac0;
              border-radius: 16px;
              border: 4px solid #fff;
            }
  
            &::-webkit-scrollbar-button {
              display: none;
            }
  
            li {
              padding: 15px;
              border-bottom: 1px solid #EEEEEE;
              border-right: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
  
              & > div {
                display: flex;
                align-items: center;
  
                a {
                  margin-right: 15px;
                  cursor: pointer;
  
                  &:hover {
                    svg {
                      path {
                        fill: #FA3535;
                      }
                    }
                  }
  
                  &:last-child {
                    margin-right: 10px;
                  }
  
                  svg {
                    display: flex;
                    transition: all .3s ease-in-out;
  
                    path {
                      transition: all .3s ease-in-out;
                    }
                  }
                }
              }
  
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  
  
  .popup-test {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }
  .popup_inner-test {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
  }
  
  .openEditPopup, .openRemovePopup, .openDuplicatePopup {
    svg {
      pointer-events: none;
      path {
        pointer-events: none;
      }
    }
  }
  
  .EditPopup, .RemovePopup, .CreatePopup {
    display: none;
    flex-direction: column;
    position: absolute;
    background: #fff;
    padding: 20px;
    width: 554px;
    left: 12%;
    top: 25%;
    border-radius: 8px;
    z-index: 9999999;
    h6 {
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 25px !important;
      color: #171B1E !important;
      text-align: left !important;
    }
    p {
      font-size: 15px !important;
      line-height: 20px !important;
      color: #717579 !important;
      text-align: left !important;
      margin-bottom: 10px !important;
    }
    input {
      background: #FFFFFF;
      border: 1px solid #D7D7D7;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      padding: 16px;
      border-radius: 10px;
      &::placeholder {
        color: #D7D7D7;
      }
    }
    .upload-new-directory_btn_group {
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      button:first-child {
        background: transparent;
        border: 1px solid #EEEEEE;
        box-sizing: border-box;
        border-radius: 4px;
        width: 108px;
        height: 34px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #2F80ED;
        margin-right: 7px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        &:hover {
          filter: contrast(1.2);
        }
      }
      button:last-child {
        background: #56CCF2;
        border: 1px solid #56CCF2;
        box-sizing: border-box;
        border-radius: 4px;
        width: 108px;
        height: 34px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #fff;
        margin-right: 7px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        &:hover {
          filter: contrast(1.2);
        }
      }
    }
  }
  
  .EditPopupCloseButton {
    background: url("./closePopupBtn.svg");
    width: 28px;
    height: 29px;
    margin-left: auto;
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
  
  .popup-overlay.settings-pods__popup-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  .RemovePopup {
    h6, p {
      text-align: center !important;
    }
    .upload-new-directory_btn_group {
      justify-content: center;
    }
  }
  
  .CreatePopup {
    top: 10%;
    input[type="date"] {
      position: relative;
      padding: 10px;
      color: #333333;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  
    input[type="date"]::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
      z-index: 1;
    }
  
    input[type="date"]:before {
      display: block;
      position: absolute;
      top: 25%;
      right: 10px;
    }
  
    .row {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      & > div {
        width: calc(50% - 10px);
        svg {
          display: flex;
        }
      }
      .createPopupDetails {
        display: flex;
        flex-direction: column;
        width: calc(50% - 10px);
        label, span {
          font-family: "Mulish";
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #4f4f4f;
          margin-bottom: 5px;
          span {
            color: #fbde57;
          }
        }
      }
    }
  }
  .CreatePopup {
    input[name="PodsName"] {
      font-family: "Mulish";
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #4f4f4f;
      margin-bottom: 5px;
      outline: none;
      &::placeholder {
        color: #4f4f4f;
      }
    }
  }
  
  .settings-pods__popup__content__body {
    border-top: 1px solid #EEEEEE;
    & > div {
      width: 40%;
      margin-left: auto;
      margin-right: 17px;
      margin-top: 10px;
      margin-bottom: 10px;
      svg {
        display: flex;
      }
    }
  }
  
  
  .CreatePopup .Toastify__toast-icon {
    svg {
      display: flex !important;
      margin-top: 0 !important;
    }
  }
  
  .successCreatePod, .failedCreatePod {
    display: none;
  }
  
  .CreatePopup {
    input[name="PodsName"] {
      transition: all .2s ease-in-out;
    }
    input.invalid {
      color: #FD5353 !important;
      border-color: #FD5353 !important;
      transition: all .2s ease-in-out;
      &::placeholder {
        color: #FD5353;
      }
    }
  }
  
  .StatusCreatedPopup, .TimePeriodCreatedPopup {
    display: none;
  }
  
  .settings-pods__popup__content__body {
    .css-26l3qy-menu {
      margin-top: -7px;
       .css-4ljt47-MenuList {
        padding: 0;
      }
    }
  }
  
  .createPopupDetails {
    .css-26l3qy-menu {
      margin-top: -7px;
      .css-4ljt47-MenuList {
        padding: 0;
      }
    }
  }
  
  .successStatus {
    display: none;
  }
  
  .EditPopup .Toastify__toast-icon {
    svg {
      display: flex !important;
      margin-top: 0 !important;
    }
  }
  
  .RemovePopup .Toastify__toast-icon {
    svg {
      display: flex !important;
      margin-top: 0 !important;
    }
  }
  
  input.podsRemove {
    display: none;
  }