.user-list {
    height: 629px;
    .MuiDataGrid-root {
        border: 0;
        .MuiDataGrid-main {
            background: #fff;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            .MuiDataGrid-cellContent {
                font-family: "Nunito Sans";
                font-weight: 600;
                font-size: 16px;
                color: #000000;
            }
            .MuiDataGrid-columnHeaderTitle {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                color: #000000;
            }
        }
        .MuiDataGrid-footerContainer {
            background-color: #fff;
        }
    }
}

.btn__new-user {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 10px;
    max-width: 216px;
    width: 100%;
    height: 45px;
    transition: all 0.2s ease-in-out;
    padding: 0 10px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #717579;
    cursor: pointer;
    svg {
        margin-right: 10px;
        path {
            transition: all 0.2s ease-in-out;
        }
    }
    &:hover {
        color: #fff;
        background: #fa3535;
        border-color: #fa3535;
        svg {
            path {
                fill: #fff;
            }
        }
    }
}

.bMgaAx div:first-child {
    overflow: visible !important;
}

.selector__user-role {
    display: flex;
    align-items: center;
    button {
        background: transparent;
        border: 0;
        cursor: pointer;
        margin-left: 10px;
        svg {
            pointer-events: none;
        }
    }
}

.info-popup {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: 202px;
    position: absolute;
    min-height: 100px;
    right: 22%;
    top: 10%;
    flex-direction: column;
    align-items: flex-start;
    display: none;
    z-index: 2;
    hr {
        border: 0;
        width: 100%;
        height: 1px;
        background: #e0e0e0;
    }
    &::before {
        content: '';
        background-image: url('../../../assets/icons/Arrow.svg');
        width: 33px;
        height: 28px;
        position: absolute;
        left: 94%;
        top: 13.25%;
        z-index: -1;
    }
    &.active {
        display: flex;
    }
    button {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
        padding: 15px 0;
        width: 100%;
        margin-left: 0;
        text-align: left;
        padding-left: 15px;
        padding-right: 15px;
        transition: all .2s ease-in-out;
        background: #fff;
        border: 0;
        cursor: pointer;
        &:first-of-type {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &:last-of-type {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        &:hover {
            color: #fff;
            background: #FA3535;
        }
    }
}
