.search {
    width: 100%;
    height: 100%;
    margin-right: 50px;
    &__filter-list {
        display: flex;
        & > div {
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
        }
        button {
            width: 143px;
            height: 60px;
            background: #FA3535;
            border-radius: 10px;
            font-weight: 700;
            font-size: 18px;
            color: #ffffff;
            outline: none;
            border: 0;
            margin-left: auto;
            cursor: pointer;
            transition: all .2s ease-in-out;
            &:hover {
                filter: contrast(1.2);
            }
        }
    }
    &__result {
        min-height: 85%;
        margin-top: 24px;
        border-top: 45px solid #fff;
        border-bottom: 45px solid #fff;
        border-radius: 10px;
        padding-bottom: 24px;
        span {
            display: flex;
            justify-content: center;
            height: 100%;
            margin: 40px auto 0;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: #171b1e;
        }
    }
}

.search__body-query {
    .search-body-title {
        font-weight: 400;
        font-size: 21px;
        color: #171b1e;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #eee;
        margin-top: 20px;
    }
}