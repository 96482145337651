.privacy-policy {
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
    border-radius: 20px;
    width: 100%;
    padding: 36px 33px;
    box-sizing: border-box;
    &__content {
        max-width: 900px;
        h3 {
            font-family: "Mulish";
            font-weight: 700;
            font-size: 25px;
            line-height: 31px;
            color: #333333;
        }
        span {
            font-family: "Mulish";
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #828282;
        }
        hr {
            height: 1px;
            background-color: #e0e0e0;
            border: 0;
            margin-top: 13px;
            margin-bottom: 39px;
        }
        h5 {
            font-family: "Mulish";
            font-weight: 700;
            font-size: 15px;
            line-height: 19px;
            color: #333333;
        }
        p {
            font-family: "Mulish";
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            color: #333333;
            margin-bottom: 15px; 
            overflow-wrap: anywhere;
        }
        ul {
            overflow-wrap: anywhere;
        }
    }
}
