.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        margin-top: -38px;
        padding: 10px 0;
    }

    hr {
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 5px;
    }

    &-inner {
        position: relative;
        padding: 20px;
        width: 100%;
        max-width: 640px;
        background-color: #fff;
        border-radius: 10px;

        .close-btn {
            position: absolute;
            top: 15px;
            right: 15px;
            border: 0;
            background: transparent;
            cursor: pointer;
        }
    }
}
