.page-description {
    min-width: 155px;
    @media screen and (max-width: 900px) {
        display: none;
    }
    h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #171b1e;
        text-transform: capitalize;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #171b1e;
    }
}
