.header__search-bar {
    height: 60px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #a0a0a0;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    @media screen and (max-width: 1100px) {
        margin-left: 20px;
        margin-right: 20px;
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
    & > div {
        width: 100%;
        height: 100%;
        & > div {
            display: flex;
            flex-direction: column;
            position: absolute !important;
            // width: calc(100% + 2px) !important;
            left: -18px !important;
            top: -24px !important;
            background: #fff;
            overflow: auto !important;
            max-height: 400px !important;
            box-shadow: none !important;
            z-index: 999;
            opacity: 1 !important;
            background: #fff !important;
            border: none !important;
            left: 0px !important;
            min-width: inherit !important;
            // width: calc(100% + 2px);
            padding-bottom: 2px !important;
            border-top-left-radius: 22px !important;
            font-size: 45px;
            // margin-left: -1px !important;
            & ul {
                border: 1px solid #a0a0a0;
                border-top: none;
                border-bottom-left-radius: 22px;
                border-bottom-right-radius: 22px;
            }
        }
    }
    input {
        height: 100%;
        width: 100%;
        border: 0;
        outline: none;
        padding-left: 21px;
        border-radius: 10px;
    }
}

.header__search-bar__query__result {
    color: #171b1e;
    text-decoration: none;
    padding: 20px;
    cursor: pointer;
    transition: all .1s ease-in-out;
    &:hover {
        background: #fa3535;
        color: #fff;
    }
}