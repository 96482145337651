.sidebar {
    ul {
        li {
            margin-bottom: 5px;
            a {
                height: 60px;
                background: #ffffff;
                border-radius: 0px 10px 10px 0px;
                display: flex;
                align-items: center;
                text-decoration: none;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #717579;
                transition: all .2s ease-in-out;
                &:hover, &.active {
                    color: #fff;
                    background: #F40027;
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
                svg {
                    width: 33px;
                    margin-left: 50px;
                    margin-right: 25px;
                    path {
                        transition: all .2s ease-in-out;
                    }
                }
            }
        }
    }
}
