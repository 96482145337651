.add-edit-stores__store-configuration {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    box-sizing: border-box;
    background: #f2f2f2;
    border-radius: 8px;
    height: 515px;
    h3 {
        font-family: "Mulish";
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
    }
    p {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
    }
    hr {
        border: 0;
        background: #e0e0e0;
        height: 1px;
        margin-top: 9px;
        margin-bottom: 22px;
    }
    h4 {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #171b1e;
    }
    input {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        height: 36px;
        padding: 0 10px;
        box-sizing: border-box;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #202020;
        width: 100%;
        &::placeholder {
            color: #202020;
        }
    }
    &__usn,
    &__address,
    &__zone {
        margin-top: 17px;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        margin-top: 17px;
    }
    &__col {
        width: calc(50% - 5px);
    }
    &__add-zone {
        background: #fade57;
        border-radius: 4px;
        width: 137px;
        height: 36px;
        box-sizing: border-box;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        color: #4f4f4f;
        border: 0;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        margin-top: 14px;
        &:hover {
            filter: contrast(1.2);
        }
    }
}

.add-extra-zones__container {
    background: #fff;
    border-radius: 4px;
    h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #171b1e;
        text-align: left;
    }
    p {
        font-size: 15px;
        line-height: 20px;
        color: #717579;
        text-align: left;
        margin-bottom: 10px;
    }
    input {
        font-family: "Mulish";
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #4f4f4f;
        margin-bottom: 5px;
        outline: none;
        background: #fff;
        width: 100%;
        height: 40px;
        padding-left: 20px;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        border-radius: 10px;
    }
    button {
        background: #56ccf2;
        border: 1px solid #56ccf2;
        box-sizing: border-box;
        border-radius: 4px;
        width: 108px;
        height: 34px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #fff;
        margin-right: 7px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
            background: #f40027;
        }
    }
}
